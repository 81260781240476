import styled from "styled-components/macro"
import { palette, fontSizes, fontWeight, gridBreakPoints, pxToRem, fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const { queries } = gridBreakPoints

export const PoolName = styled.span`
  font-size: 1rem;
  font-weight: ${fontWeight.bold};
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: left;
  color: ${palette.gray20};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${queries.sm} {
    line-height: 1;
  }

  @media (hover: hover) {
    :hover {
      color: ${palette.lightBlue3};
    }
  }
`
export const PoolRowWrap = styled.div<{ contentHeight?: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${palette.white};
  box-shadow: none;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;

  & {
    & > label.accordion-header {
      border-top: 1px solid ${palette.gray90};
    }
  }

  input[name="pool-check"] {
    display: none;
  }

  & > label.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    & > div.left-side {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex: 1;
      overflow: hidden;

      & > div.logo-wrap {
        position: relative;
        height: 2.5rem;
        width: 2.5rem;

        & > div.logo {
          display: inline-block;
          position: relative;
          vertical-align: middle;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          overflow: hidden;

          img {
            height: auto;
            max-width: 100%;
            opacity: 1;
            transition: opacity 0.5s ease-in 0.5s;
            vertical-align: top;
          }
        }
        & > div.pool-manager-info {
          position: absolute;
          width: 1rem;
          height: 1rem;
          top: ${pxToRem(-2)};
          right: ${pxToRem(-2)};
          color: ${palette.yellow};
        }
      }

      & > div.pool-information {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        & > span.pool-members-count {
          font-size: 12px;
          font-weight: ${fontWeight.regular};
          line-height: 1;
          letter-spacing: -0.1px;
          text-align: left;
          display: none;
          color: ${palette.gray40};
          margin-top: 0.25rem;
        }
      }
    }

    & > div.right-side {
      display: none;
    }
  }

  & > div.accordion-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    padding: 0 1rem 1rem;
    background-color: white;
    transition: height 0.25s ease-in-out;

    & > .pool-row-entry {
      margin-top: 0;
    }

    & > .pool-row-entry ~ .pool-row-entry {
      margin-bottom: 0.5rem;
    }

    & > div {
      margin-top: 0.5rem;
    }
  }

  ${queries.sm} {
    border: 1px solid ${palette.gray90};
    border-radius: 0.5rem;

    & {
      & > label.accordion-header {
        border-top: 0;
      }
    }

    & > label.accordion-header {
      & > div.left-side {
        & > div.pool-information {
          & > span.pool-members-count {
            display: flex;
          }
        }
      }

      & > div.right-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1rem;

        & > div.chevron-icon {
          margin-left: 1rem;
          color: ${palette.gray50};

          svg {
            transition: all 0.25s ease-in-out;
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        & > div.incomplete-brackets {
          display: flex;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 100%;
          background: ${palette.darkRed};
          margin-right: 6px;
        }

        & > span.brackets-count {
          font-variant-numeric: tabular-nums;
          font-size: 14px;
          font-weight: ${fontWeight.medium};
          line-height: 20px;
          letter-spacing: -0.1px;
          text-align: right;
        }
      }
    }

    & > div.accordion-content {
      padding-bottom: 0;
      height: 0;

      & > .pool-row-entry {
        margin-top: 0.5rem;
      }

      & > .pool-row-entry ~ .pool-row-entry {
        margin-bottom: 0;
      }

      & > div {
        margin-top: 0.5rem;
      }
    }

    input[name="pool-check"] :checked ~ div.accordion-content {
      height: ${({ contentHeight }) => (contentHeight ? pxToRem(contentHeight) : "0")};
    }

    input[name="pool-check"] :checked ~ label.accordion-header {
      & > div.right-side {
        & > div.chevron-icon {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
`
export const EntryRowHeader = styled.div`
  display: none;
  flex-direction: row;
  padding: 0 1rem;

  & > span {
    font-style: normal;
    font-weight: ${fontWeight.medium};
    font-size: ${fontSizes.small};
    line-height: 1;
    display: flex;
    align-items: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: ${palette.gray50};
    font-family: ${fontFamily.condensed};
  }

  & > span.rank-title {
    width: 4rem;
  }
  & > span.champion-title {
    width: 5rem;
  }

  & > span.champion-name {
    flex: 3;
    padding-right: 0.5rem;
  }
  & > span.champion-status {
    flex: 3;
  }

  & > span.stats-title {
    width: 2rem;
    margin-left: 1.5rem;
    justify-content: flex-end;
  }

  ${queries.sm} {
    display: flex;
    & > span.champion-title {
      margin-right: 1.5rem;
    }
    & > span.align-status-column {
      margin-right: 7rem;
    }
  }
`

import styled from "styled-components/macro"
import { fontFamily, fontWeight, fontSizes, palette, gridBreakPoints, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
const { queries } = gridBreakPoints

export const MyGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${queries.sm} {
    flex-direction: column-reverse;
  }
`
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${palette.white};
  box-shadow: none;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  border-top: 1px solid ${palette.gray90};
  border-bottom: 1px solid ${palette.gray90};

  & > div.title-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 0;

    & > span.title {
      font-style: normal;
      font-weight: bold;
      font-size: ${pxToRem(18)};
      line-height: 1.5rem;
      text-transform: uppercase;
      display: block;
      color: ${palette.gray20};
    }

    & > .more-btn-options {
      display: none;
    }
  }

  & > div.title-wrap.with-border {
    border-bottom: 1px solid ${palette.gray90};
  }

  & > div.content {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    & > div.information {
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 1rem;
      color: ${palette.gray40};
      & > span.icon-container {
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        margin-right: 1rem;
        & > svg {
          color: ${palette.lightBlue3};
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      & > span.text {
        font-style: normal;
        font-weight: normal;
        font-size: ${fontSizes.regular};
        line-height: 1.25rem;
        display: flex;
        flex: 1;
        align-items: center;
        letter-spacing: -0.1px;
      }
    }
  }
  & .buttons {
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    flex-direction: row;
    max-width: none;
    box-sizing: border-box;
    justify-content: space-between;
    & > a {
      width: calc(50% - 0.5rem);
    }
  }

  & .buttons.is-locked {
    & > a {
      width: 100%;
    }
  }

  & > div.no-pool-list-locked {
    padding: 1rem;
    box-sizing: border-box;
    font-family: ${fontFamily.base};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${fontWeight.regular};
    line-height: 1.5;
    letter-spacing: -0.1px;
    text-align: left;
    color: ${palette.gray40};
  }

  & > div.no-pool-list-archived-pools-message {
    font-size: 0.875rem;
    color: ${palette.gray40};
    padding: 0 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    a {
      margin-left: 0.25rem;
      color: ${palette.uiBlue1};
    }
  }
  & > div.no-pool-list-archived-pools-message.padded {
    padding-top: 1rem;
  }

  ${queries.sm} {
    padding: 0 1.5rem;
    border: 1px solid ${palette.gray90};
    border-radius: 0.5rem;
    margin-bottom: 0;

    & > div.title-wrap {
      padding: 1.25rem 0;

      & > span.title {
        font-size: ${fontSizes.large};
      }

      & > .more-btn-options {
        display: flex;
      }
    }

    & > div.content {
      flex-direction: row;
      padding: 1.5rem 0;

      & > div.information {
        margin-bottom: 0;

        & > span.text {
          font-size: ${fontSizes.medium};
          line-height: 1.5rem;
        }
      }
    }

    & .buttons {
      & > a {
        width: auto;
      }
    }

    & .buttons.with-margin {
      & > a:first-child {
        margin-right: 1rem;
      }
    }

    & > div.my-pool-list {
      padding: 0 0 1.5rem;
      box-sizing: border-box;

      & > div:not(:first-child) {
        margin-top: 0.75rem;
      }
    }
    & > div.my-pool-list.with-margin {
      margin-top: 0.75rem;
    }

    & > div.my-invite-list {
      padding: 0;
      box-sizing: border-box;
    }
    & > div.my-invite-list.padded {
      padding-bottom: 0.75rem;
    }

    & > div.no-pool-list-locked {
      padding: 1.5rem 0;
    }
  }
`

export const StyledLeavePoolButton = styled(Button)`
  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }

  &.variant--secondary {
    &:hover:not(:active) {
      background-color: ${palette.lightestBlue};
    }
  }
`

import React from "react"
import styled from "styled-components"
import { pxToRem, newPalette, buildClassNames } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Check from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CircleCheck"
import { ProductInfoType } from "../../../../hooks/useMainPromoInfo"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"

interface GamePromoItemProps {
  logoNode?: React.ReactNode
  ctaNode?: React.ReactNode
  data: ProductInfoType
  className?: string
}

const GamePromoItemRoot = styled.div`
  flex: 1;
  padding: 0 1.5rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr minmax(92px, 1fr) 1fr;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    "check logo count"
    "title title title"
    "body body body"
    "cta cta cta";

  .item__wrapper--logo {
    grid-area: logo;
  }

  .item__wrapper--count {
    grid-area: count;
    place-self: start end;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(14)};
    color: ${newPalette.neutral20};
  }

  .item__wrapper--cta {
    grid-area: cta;
    place-self: end center;
    padding-top: 0.5rem;
  }

  .item__header {
    grid-area: title;
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(20)};
    font-weight: 500;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
  }

  .item__body {
    grid-area: body;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
    text-align: center;
    color: ${newPalette.gray40};
    letter-spacing: -0.1px;
  }

  .item__checkmark {
    grid-area: check;
    place-self: start;
    max-width: ${pxToRem(20)};
    max-height: ${pxToRem(20)};
    color: ${newPalette.gray90};
  }

  .item__checkmark--completed {
    color: ${newPalette.green};
  }
`

const GamePromoItem: React.VFC<GamePromoItemProps> = ({
  data: { numberOfEntries, maxNumberOfEntries, remainingEntries, copy, header, gameInstanceUid },
  logoNode,
  ctaNode,
  ...rest
}) => {
  const dataIdentifier = `${isNCAAWTournamentMatcher.test(gameInstanceUid) ? "ncaaw" : "ncaab"}`
  return (
    <GamePromoItemRoot {...rest}>
      <Check className={buildClassNames({ item__checkmark: true, "item__checkmark--completed": numberOfEntries > 0 })} />
      <div className="item__wrapper--logo">{logoNode}</div>
      <p className="item__wrapper--count">{numberOfEntries > 0 ? `${numberOfEntries} of ${maxNumberOfEntries}` : ""}</p>
      <p className="item__header" data-cy={`${dataIdentifier}-header`}>
        {header}
      </p>
      <p className="item__body" data-cy={`${dataIdentifier}-body`}>
        {copy}
      </p>
      {remainingEntries > 0 && (
        <div className="item__wrapper--cta" data-cy={`${dataIdentifier}-btn`}>
          {ctaNode}
        </div>
      )}
    </GamePromoItemRoot>
  )
}

export default GamePromoItem

import React, { useEffect } from "react"
import styled from "styled-components"
import { pxToRem, newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const SingleGameRoot = styled.div`
  background: ${newPalette.white};
  padding: 1rem;
  background-image: url("/static/sport-assets/basketball/pool-setup-bg.svg");
  background-size: cover;
  border: solid 1px ${newPalette.neutral10};

  .item__header {
    font-size: ${pxToRem(18)};
    font-weight: 900;
    letter-spacing: -0.1px;
    text-align: center;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }

  .item__body {
    font-size: ${pxToRem(14)};
    letter-spacing: -0.1px;
    line-height: 1.25rem;
    text-align: center;
    padding: 0 2rem;
    color: ${newPalette.gray40};
  }

  .item__wrapper--cta {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .item__wrapper--rules {
    font-size: ${pxToRem(10)};
    line-height: ${pxToRem(18)};
    text-align: center;
    letter-spacing: -0.1px;
    color: ${newPalette.gray40};

    a {
      text-decoration: underline;
    }
  }
`
type GamePromoItemMobileProps = {
  header: string
  copy: string
  rulesUrl: string
  ctaNode?: React.ReactNode
  impressionTracking?: () => void
}

const GamePromoItemMobile: React.VFC<GamePromoItemMobileProps> = ({ header, copy, ctaNode, rulesUrl, impressionTracking }) => {
  useEffect(() => {
    impressionTracking?.()
  }, [impressionTracking])
  return (
    <SingleGameRoot>
      <div className="item__header">{header}</div>
      <div className="item__body">{copy}</div>
      <div className="item__wrapper--cta">{ctaNode}</div>
      <div className="item__wrapper--rules">
        No Purchase Necessary -{" "}
        <a href={rulesUrl} rel="nofollow noopener noreferrer" target="_blank">
          See Official Rules
        </a>
      </div>
    </SingleGameRoot>
  )
}

export default GamePromoItemMobile

import React from "react"
import { ListItemIcon } from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import { IPoolRowProps } from "../MyGroups.types"
import CirclePlusSvg from "../../../../../components/icons/CirclePlus"
import { Wrapper, CreateBracketText } from "./AddEntryRow.styles"
import ChevRightSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ChevRight"
import Spinner from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Spinner"
import { PoolSettingsTypesEnum } from "../../../../../../common/enums"

const AddEntryRow = ({ onCreateEntry, entries, showSpinner }: IPoolRowProps) => {
  const [first] = entries
  const count = entries.length
  const { pool } = first
  const { poolSettings } = pool

  const maxEntriesPerUser = poolSettings.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings.maxEntriesPerUser

  const text = <CreateBracketText>{`Add bracket to pool (${count + 1} of ${maxEntriesPerUser}). `}</CreateBracketText>

  return (
    <Wrapper type="button" onClick={(e) => onCreateEntry(e, pool)}>
      <div className="text-wrapper">
        <ListItemIcon size="lg">
          <CirclePlusSvg />
        </ListItemIcon>
        {text}
      </div>
      <div className="btn-content">
        <span className="mobile-content--false">
          {showSpinner && (
            <ListItemIcon alignment="left" size="sm">
              <Spinner />
            </ListItemIcon>
          )}
          Create Bracket
        </span>
        <span className="mobile-content--true">
          <ChevRightSvg />
        </span>
      </div>
    </Wrapper>
  )
}

export default AddEntryRow

import React, { useCallback, useState, useMemo } from "react"
import { CardWrapper, MyGroupsWrapper, StyledLeavePoolButton } from "./MyGroups.styles"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import StyledButton from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button.styles"

// build/cjs/components
import { TPoolRouteProps } from "../../../../../routes.d"
import PoolRow from "./PoolRow"
import {
  CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node,
  CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool,
} from "../../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { getAuthSearch, withoutDomain } from "../../../../utils/url-utils"
import Link from "../../../../components/Link"
import useUpsertEntryMutation from "../../../../hooks/useUpsertEntryMutation"
import { EntryNameModal } from "../../../PoolSetupPages/components/CreateEntryForm"
import DeleteABracketModal from "../EntryModals/DeleteABracketModal"
import AddBracketComponent from "../MultipleEntriesArea/AddBracketComponent"
import { StyledAddBracketComponent } from "../MultipleEntriesArea/AddBracketComponent/AddBracketComponent.styles"
import InviteRow from "../InviteRow/InviteRow"
import LeavePoolModal, { LeavePoolDataType } from "../EntryModals/LeavePoolModal"
import LeaveSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Leave"
import { useDeviceType } from "../../../../Base/DeviceType"
import { useHistory } from "react-router-dom"
import Analytics from "../../../../utils/analytics"
import { buildPickUtils } from "../../../../hooks/usePoolData"
import PickUtilsContext from "../../../../Contexts/PickUtilsContext"
import constants from "../../../../../common/constants"
import { PoolRowWrap } from "./PoolRow/PoolRow.styles"
import { BracketSvg } from "@cbs-sports/sports-shared-client/build/cjs/components/icons"

interface IMyGroups extends TPoolRouteProps {
  isLoggedIn: boolean
  myEntries: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[]
}

const manageSPOEURL = constants.IS_PROD ? "https://www.cbssports.com/fantasy/games/my-teams/" : "https://qa.cbssports.com/fantasy/games/my-teams"

const MyGroups = (props: IMyGroups) => {
  const [createEntryData, setCreateEntryData] = useState<CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool | undefined>(undefined)
  const [selectedPoolId, setSelectedPoolId] = useState<string | undefined>(undefined)

  const { upsertEntryMutation } = useUpsertEntryMutation()
  const deviceType = useDeviceType()
  const history = useHistory()

  const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false)
  const [showLeavePoolModal, setShowLeavePoolModal] = useState<boolean>(false)

  const leavingPools = useMemo<LeavePoolDataType>(
    () =>
      props.myEntries.reduce((obj, current) => {
        const isChallengePool = /challenge/.test(current.gameInstanceUid)
        if (isChallengePool) {
          return obj
        } else {
          const k = current.pool.id
          return {
            ...obj,
            [k]: {
              id: k,
              name: current.pool.name,
              canLeavePool: current.canLeavePool,
            },
          }
        }
      }, {}),
    [props.myEntries],
  )
  const canLeaveSomePool = Object.keys(leavingPools).some((k) => leavingPools[k].canLeavePool)

  const openLeavePoolModal = () => {
    Analytics.trackAction("brackets welcome", "picking options", "leave pool")
    setShowLeavePoolModal(true)
  }
  const closeLeavePoolModal = () => setShowLeavePoolModal(false)

  const { location, poolData, isLoggedIn, myEntries } = props

  const trackClickAction = (pool: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool) => {
    switch (pool.gameInstanceUid) {
      case constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID:
      case constants.NISSAN_NCAAB_CHALLENGE_GAME_INSTANCE_UID:
        // mens-challenge
        Analytics.trackAction("challenge entry", "lobby pools", "bpc_create bracket")
        break
      case constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID:
      case constants.NISSAN_NCAAW_CHALLENGE_GAME_INSTANCE_UID:
        // womens-challenge
        Analytics.trackAction("challenge entry", "lobby pools", "wbpc_create bracket")
        break
      default:
        // manager
        Analytics.trackAction("brackets welcome", "picking options", "create bracket")
        break
    }
  }

  const handleCreateEntry = (event: React.SyntheticEvent, pool: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool) => {
    trackClickAction(pool)
    if (deviceType === "handheld") {
      const roadblockUrl = `/games/roadblock?showCongrats=false&from=lobby&gameType=${pool.gameInstanceUid}`
      history.push(withoutDomain(roadblockUrl))
    } else {
      const importableBrackets = myEntries.filter((e) => e.hasMadeAPick)
      if (importableBrackets.length > 0) {
        setCreateEntryData(pool)
      } else {
        const { isInComingSoon } = poolData
        if (pool) {
          setSelectedPoolId(pool?.id)
          upsertEntryMutation({
            variables: {
              poolId: pool?.id,
              gameInstanceUid: pool?.gameInstanceUid,
            },
          })
            .then((res) => {
              setSelectedPoolId(undefined)
              // NOTE qac: if there is no onFinish, that means this component gets unmounted after success
              if (!isInComingSoon) {
                const entry = res.data?.upsertEntry?.entry || emptyObject
                const { url } = entry
                history.push(withoutDomain(url))
              }
              Analytics.trackInteraction("name entry - success", { createEntry: true })
            })
            .catch((err) => {
              setSelectedPoolId(undefined)
              Analytics.trackInteraction("name entry - fail")
            })
        }
      }
    }
  }
  const handleOnNewEntryFinished = () => {
    setCreateEntryData(undefined)
  }

  const { isCbsAppWebview, currentUserPendingInvites, productSeason, centralBracketState } = poolData
  const isLocked = centralBracketState?.bracketState?.isLocked

  const groupByEntries = useCallback(() => {
    const result: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[][] = []
    const grouped = myEntries.reduce((total, entry) => {
      const { pool } = entry
      const { id } = pool
      total[id] = total[id] || []
      total[id].unshift(entry)
      return total
    }, {})
    Object.keys(grouped).forEach((g) => {
      result.push(grouped[g])
    })

    return result
  }, [myEntries])

  const groupedEntries = groupByEntries()

  const { pathname, search: currentSearch } = location
  const search = getAuthSearch(`${pathname}${currentSearch ?? ""}`, productSeason?.productAbbrev, productSeason?.masterProductId, isCbsAppWebview)

  const isCreate = Boolean(createEntryData)
  const { id: poolId, gameInstanceUid, season } = createEntryData || emptyObject
  // const isWomenBracket = pdGameInstanceUid === constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID
  const visibleEntries = myEntries.filter((entry) => entry.isVisible)
  const hasVisibleEntries = Boolean(visibleEntries.length)
  const { currentEntriesForArea } = poolData.myEntriesForArea() || {}
  const hasHiddenEntries = visibleEntries.length !== currentEntriesForArea.length
  const hasInvites = Boolean(currentUserPendingInvites?.length)
  const entryNameModal = (
    <EntryNameModal
      isOpen={isCreate}
      isCopyOrCreate
      onFinish={handleOnNewEntryFinished}
      mutation={upsertEntryMutation}
      gameInstanceUid={gameInstanceUid}
      poolId={poolId}
      entries={myEntries}
      season={season}
    />
  )

  const removeEntryModal = (
    <DeleteABracketModal
      isOpen={showDeleteModal}
      entries={props.myEntries}
      close={() => setshowDeleteModal(false)}
      gameDataVariables={poolData.gameDataVariables}
    />
  )

  const leavePoolModal = (
    <LeavePoolModal poolData={props.poolData} leavingPools={leavingPools} isOpen={showLeavePoolModal} close={closeLeavePoolModal} />
  )

  const leaveAPoolButton = (
    <StyledLeavePoolButton onClick={openLeavePoolModal} variant="secondary">
      <LeaveSvg /> <span>Leave A Pool</span>
    </StyledLeavePoolButton>
  )
  const showLeaveAPoolButton = isLoggedIn && hasVisibleEntries && canLeaveSomePool && deviceType !== "handheld"
  const pickUtils = buildPickUtils(poolData, null)

  return (
    <PickUtilsContext.Provider value={pickUtils}>
      <MyGroupsWrapper>
        <CardWrapper>
          <div
            className={`title-wrap ${cx({
              "with-border":
                (isLoggedIn && isLocked && !hasVisibleEntries && !hasInvites) ||
                (deviceType === "handheld" && (!isLocked || !isLoggedIn || hasInvites)),
            })}`}
          >
            <span className="title" data-cy="mypools-title">
              {isLoggedIn || isLocked ? "My Pools" : "Create a Pool"}
            </span>
            {showLeaveAPoolButton && leaveAPoolButton}
          </div>
          {!isLoggedIn && (
            <PoolRowWrap style={{ marginBottom: "1.5rem" }}>
              <div className="content" style={{ padding: "1.125rem" }}>
                <StyledAddBracketComponent>
                  <div className="information">
                    <div className="icon-container">
                      <BracketSvg style={{ width: "2.5rem", height: "2.5rem" }} />
                    </div>
                    <div className="content-wrap">
                      <span className="description" data-cy="mypools-label">
                        {!isLocked ? "Log in to view or create a bracket pool." : "Log in to view your bracket pools."}
                      </span>
                    </div>
                  </div>
                  <div
                    className={cx({
                      buttons: true,
                      "with-margin": !isLocked,
                      "is-locked": isLocked,
                    })}
                  >
                    {!isLocked && (
                      <StyledButton
                        as={Link}
                        to={{
                          pathname: "/auth/signup",
                          search,
                        }}
                        className="variant--secondary"
                        data-cy="sign-up-btn"
                      >
                        Sign Up
                      </StyledButton>
                    )}
                    <StyledButton
                      className="variant--primary"
                      as={Link}
                      to={{
                        pathname: "/auth/login",
                        search,
                      }}
                      data-cy="log-in-btn"
                    >
                      Log In
                    </StyledButton>
                  </div>
                </StyledAddBracketComponent>
              </div>
            </PoolRowWrap>
          )}

          {isLoggedIn && (
            <div
              className={cx({
                "my-invite-list": true,
                padded: hasInvites && !isLocked,
              })}
            >
              {currentUserPendingInvites.map((invite) => {
                return <InviteRow invite={invite} isLoggedIn={isLoggedIn} />
              })}
            </div>
          )}
          {isLoggedIn && !isLocked && (
            <PoolRowWrap style={{ marginBottom: "0.75rem" }}>
              <div className="content" style={{ padding: "1.125rem" }}>
                <AddBracketComponent routeProps={props} variant="bpm" purpose="createPool" useAsComponent={false} myVisibleEntries={visibleEntries} />
              </div>
            </PoolRowWrap>
          )}
          {isLoggedIn && hasVisibleEntries && (
            <div
              className={cx({
                "my-pool-list": true,
                "with-margin": currentUserPendingInvites?.length > 0 && isLocked,
              })}
            >
              {groupedEntries.map((group, index) => {
                const [first] = group || ([] as never)
                const { pool } = first || ({} as never)
                const { id } = pool || ({} as never)
                const showSpinner = id === selectedPoolId
                const key = `pool-group-key--${id}--${index}`
                return <PoolRow entries={group} onCreateEntry={handleCreateEntry} key={key} showSpinner={showSpinner} />
              })}
            </div>
          )}

          {isLoggedIn && !hasVisibleEntries && isLocked && <div className="no-pool-list-locked">You do not have any bracket pools.</div>}
          {isLoggedIn && !hasVisibleEntries && hasHiddenEntries && (
            <div
              className={cx({
                "no-pool-list-archived-pools-message": true,
                padded: !isLocked,
              })}
            >
              <span>
                Cannot find a previous pool? Check your
                <a href={manageSPOEURL} rel="noreferrer" target="_blank">
                  archived pools
                </a>
                .
              </span>
            </div>
          )}
        </CardWrapper>
        {entryNameModal}
        {removeEntryModal}
        {leavePoolModal}
      </MyGroupsWrapper>
    </PickUtilsContext.Provider>
  )
}

export default MyGroups

import React, { FC, useState } from "react"
import { useMutation } from "@apollo/client"
import styled from "styled-components"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import Checkbox from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { RemoveEntriesMutation, RemoveEntriesMutationVariables } from "../../../../../__generated__/RemoveEntriesMutation"
import { CentralCurrentUsersEntriesQuery } from "../../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { CENTRAL_CURRENT_USERS_ENTRIES_QUERY } from "../../../../utils/central-queries"
import { REMOVE_ENTRIES_MUTATION } from "../../../queries"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import EntryLogo from "../EntryLogo"
import { TGameDataVars } from "../../../../hooks/usePoolData"

const px4 = pxToRem(4)
const px12 = pxToRem(12)
const px14 = pxToRem(14)
const px24 = pxToRem(24)
const px80 = pxToRem(80)
const px215 = pxToRem(215)
const px488 = pxToRem(488)

const DeleteABracketModalRoot = styled.div`
  width: calc(${px488} - ${px80});
  display: flex;
  flex-direction: column;

  .modal__bracket-list {
    border-radius: ${px4};
    border: solid 1px ${theme.colors.overLight.white90};
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    min-height: 1rem;
    max-height: ${px215};
    overflow: hidden auto;
  }

  .modal__bracket-item {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .label-content {
      margin-left: 2rem;
      min-width: 0;

      &.is-disabled {
        .modal__overflow-container,
        .modal__entry-logo img {
          opacity: 0.4;
        }
        .modal__entry-logo:empty {
          background: ${theme.colors.overLight.white97};
        }
      }
    }

    svg.checkbox-icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .modal__bracket-item-label {
    display: flex;
    align-items: center;
  }

  .modal__entry-logo {
    display: block;
    min-width: ${px24};
    max-width: ${px24};
    min-height: ${px24};
    max-height: ${px24};
    margin-right: 0.5rem;
  }

  .modal__overflow-container {
    min-width: 0;
  }

  .modal__text--main-line {
    font-size: ${px14};
    line-height: ${px14};
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white20};
    font-weight: ${theme.fonts.weights.semireg};
    margin-bottom: 0.24rem;
  }

  .modal__text--secondary-line {
    font-size: ${px12};
    line-height: ${px12};
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white40};
  }

  .modal__error-wrapper {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
`

const DEFAULT_MODAL_TITLE = "Delete A Bracket"
const MODAL_SUBTITLE = "Select a bracket(s) you would like to delete*:"
const MODAL_FOOTER = "*You can’t undo this action."

const modalActionProps = { dense: true }

interface IDeleteABracketModalProps extends IGenericEntryModalProps {
  close: () => void
  entries: Array<CentralCurrentUsersEntriesQuery["currentUser"]["entries"]["edges"][number]["node"]>
  gameDataVariables: TGameDataVars
}

const DeleteABracketModal: FC<IDeleteABracketModalProps> = ({ title = DEFAULT_MODAL_TITLE, entries = [], gameDataVariables, close, ...rest }) => {
  const [removeEntries, { loading: removingEntries, error }] = useMutation<RemoveEntriesMutation, RemoveEntriesMutationVariables>(
    REMOVE_ENTRIES_MUTATION,
    {
      refetchQueries: [{ query: CENTRAL_CURRENT_USERS_ENTRIES_QUERY, variables: gameDataVariables }],
      awaitRefetchQueries: true,
    },
  )
  const [entryIds, setEntryIds] = useState<Array<string>>([])
  const clearEntries = () => setEntryIds([])

  const toggleEntrySelection = (entryId: string) => {
    const index = entryIds.indexOf(entryId)
    if (index >= 0) {
      const newSelection = [...entryIds]
      newSelection.splice(index, 1)
      setEntryIds(newSelection)
    } else {
      setEntryIds([...entryIds, entryId])
    }
  }

  const handleDeleteEntries = () => {
    removeEntries({ variables: { entryIds } }).then(({ data }) => {
      close()
      clearEntries()
      const removedEntries = data?.removeEntries.length ?? 0
      const isPlural = removedEntries === 0 || removedEntries > 1
      toast.snackbar(`${removedEntries} bracket${isPlural ? "s" : ""} deleted`)
    })
  }

  const actions = (
    <>
      <Button variant="secondary" type="button" onClick={close} disabled={removingEntries}>
        Cancel
      </Button>
      <Button variant="primary" type="button" disabled={entryIds.length <= 0} onClick={handleDeleteEntries} withLoading loading={removingEntries}>
        {removingEntries ? "Deleting..." : "Delete"}
      </Button>
    </>
  )

  const entryItems = entries.map((entry) => (
    <Checkbox
      key={entry.id}
      name={`deleteEntry-${entry.id}`}
      className="modal__bracket-item"
      onChange={() => toggleEntrySelection(entry.id)}
      isChecked={entryIds.includes(entry.id)}
      disabled={!entry.canDeleteEntry}
      label={
        <div className="modal__bracket-item-label">
          <EntryLogo className="modal__entry-logo" entry={entry} showChampionLogo size="rg" />
          <div className="modal__overflow-container">
            <div className="modal__text--main-line modal__text--overflow">{entry.name}</div>
            <div className="modal__text--secondary-line modal__text--overflow">{entry.pool.name}</div>
          </div>
        </div>
      }
    />
  ))

  return (
    <GenericEntryModal
      title={title}
      onBackgroundClick={noop}
      onEscapeKeydown={noop}
      actions={actions}
      actionProps={modalActionProps}
      afterClose={clearEntries}
      {...rest}
    >
      <DeleteABracketModalRoot>
        <div className="modal__text--body1">{MODAL_SUBTITLE}</div>
        <div className="modal__bracket-list">{entryItems}</div>
        <div className="modal__text--body2">{MODAL_FOOTER}</div>
        {error && (
          <div className="modal__error-wrapper">
            <div className="modal__text--error">Error deleting your bracket please try again</div>
          </div>
        )}
      </DeleteABracketModalRoot>
    </GenericEntryModal>
  )
}

export default DeleteABracketModal

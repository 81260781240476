import React from "react"
import { PicksExporterUtil } from "@cbs-sports/sports-shared-client/build/cjs/utils/picks-exporter-util"
import { emptyArray } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { ENUM_CHALLENGE } from "@cbs-sports/sports-shared-client/build/cjs/utils/enums"
import PoolDataContext from "../Contexts/PoolDataContext"
import useUpsertEntryMutation from "./useUpsertEntryMutation"
import constants from "../../common/constants"
import { MutationFunctionOptions } from "@apollo/client"
import { UpsertEntryMutation, UpsertEntryMutationVariables } from "../../__generated__/UpsertEntryMutation"
import { NCAAB_S16_GAME_INSTANCE_UID, NCAAB_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"

type ImportablePick = {
  tournamentRound: number
  roundOrdinal: number
  cbsTeamId: number
}
type UpsertEntryMutationType = ReturnType<typeof useUpsertEntryMutation>["upsertEntryMutation"]

type ImportType = "NONE" | "PARTIAL" | "FULL"
type ActionType = "CREATE" | "REPLACE" | "ADD"

const getChallengeMaxEntriesPerUserByGameInstance = (gameInstanceUid: string = NCAAB_GAME_INSTANCE_UID) => {
  if (gameInstanceUid === NCAAB_S16_GAME_INSTANCE_UID) {
    return constants.NCAAB_S16_CHALLENGE_MAX_ENTRIES_PER_USER
  }
  return constants.NCAAB_CHALLENGE_MAX_ENTRIES_PER_USER
}
function usePicksImport(gameInstanceUid: string, cookieName?: string) {
  const poolData = React.useContext(PoolDataContext)
  const [importablePicks, setImportablePicks] = React.useState<Array<ImportablePick>>([])
  const [importType, setImportType] = React.useState<ImportType>("NONE")
  const [actionType, setActionType] = React.useState<ActionType>("CREATE")

  const challengeEntries = poolData?.allCurrentEntries.filter((e) => e.gameInstanceUid === gameInstanceUid) ?? emptyArray
  const importIntoPeriod = poolData?.gameInstancesForArea.find((gi) => gi.poolType === ENUM_CHALLENGE)?.currentPeriod?.id

  const maxEntriesCount = getChallengeMaxEntriesPerUserByGameInstance(gameInstanceUid)

  const { upsertEntryMutation, upsertEntryMutationResult } = useUpsertEntryMutation()

  const canImportPicks = importType === "PARTIAL" || importType === "FULL"

  const updateImportType = React.useCallback((importPicksCount: number, isCookieConsumed: boolean) => {
    if (importPicksCount <= 0 || isCookieConsumed) {
      setImportType("NONE")
    } else if (importPicksCount >= 63) {
      setImportType("FULL")
    } else {
      setImportType("PARTIAL")
    }
  }, [])

  const updateActionType = React.useCallback(
    (entriesCount: number) => {
      const isUserFull = entriesCount >= maxEntriesCount
      const isAdd = entriesCount >= 1
      const newActionType: ActionType = isUserFull ? "REPLACE" : isAdd ? "ADD" : "CREATE"
      setActionType(newActionType)
    },
    [maxEntriesCount],
  )

  const readCookie = React.useCallback(() => {
    const serializedCookie = PicksExporterUtil.serializeCookie(cookieName, false)
    if (serializedCookie?.hasSubmitted) {
      const importablePicksResult =
        serializedCookie?.picks.map((e) => ({ tournamentRound: e.tournamentRound, roundOrdinal: e.roundOrdinal, cbsTeamId: e.cbsTeamId })) ??
        emptyArray

      setImportablePicks(importablePicksResult)

      const isCookieConsumed = serializedCookie?.consumed ?? false
      updateImportType(importablePicksResult.length, isCookieConsumed)

      updateActionType(challengeEntries.length)
    }
  }, [cookieName, updateImportType, updateActionType, challengeEntries.length])

  React.useEffect(() => {
    readCookie()
  }, [readCookie])

  const discardCookie = () => {
    PicksExporterUtil.markAsConsumed(undefined, undefined, cookieName, false)
    PicksExporterUtil.expireCookie(cookieName)
    setImportType("NONE")
    setTimeout(() => {
      readCookie()
    }, 100)
  }

  const upsertEntry: UpsertEntryMutationType = (options?: MutationFunctionOptions<UpsertEntryMutation, UpsertEntryMutationVariables>) => {
    if (!canImportPicks) {
      return Promise.reject("Could not import picks")
    }

    return upsertEntryMutation({
      ...options,
      variables: {
        ...options?.variables,
        picks: importablePicks,
        periodId: importIntoPeriod,
      },
    }).then((res) => {
      discardCookie()
      return res
    })
  }

  return {
    upsertEntryResult: upsertEntryMutationResult,
    challengeEntries,
    importablePicks,
    canImportPicks,
    importType,
    actionType,
    upsertEntry,
    discardCookie,
    isSingleEntry: maxEntriesCount === 1,
  }
}

export type PicksImportType = ReturnType<typeof usePicksImport>

export default usePicksImport

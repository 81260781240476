import React, { FC, useState, useCallback } from "react"
import { useMutation, defaultDataIdFromObject } from "@apollo/client"
import styled from "styled-components"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import Checkbox from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import { LeavePools, LeavePoolsVariables } from "../../../../../__generated__/LeavePools"
import { LEAVE_POOLS_MUTATION } from "../../../queries"
import { TPoolRouteProps } from "../../../../../routes.d"
import { CENTRAL_CURRENT_USERS_ENTRIES_QUERY } from "../../../../utils/central-queries"
import constants from "../../../../../common/constants"

type LeavePoolDataItem = {
  id: string
  name: string
  canLeavePool: boolean
}
export type LeavePoolDataType = {
  [k: string]: LeavePoolDataItem
}

const DEFAULT_TITLE = "Leave A Pool"
const LIST_HEADER_TEXT = "Select pool(s) you would like to leave*:"
const LIST_FOOTER_TEXT = [
  "*Brackets in pools you leave will be permanently deleted.",
  "*You cannot leave a pool if you are the only manager within that pool.",
  "*You cannot leave a pool after the tournament has started if you have picks.",
]
const manageSPOEURL = constants.IS_PROD ? "https://www.cbssports.com/fantasy/games/my-teams/" : "https://qa.cbssports.com/fantasy/games/my-teams"

const px4 = pxToRem(4)
const px12 = pxToRem(12)
const px14 = pxToRem(14)
const px170 = pxToRem(170)

const LeavePoolWrapper = styled.div`
  width: calc(${pxToRem(488)} - ${pxToRem(80)});
  display: flex;
  flex-direction: column;

  .modal__settings-link {
    a {
      text-decoration: underline;
    }
  }
  .modal__row--pool-list {
    margin-top: ${px12};
    margin-bottom: ${px12};

    .pool-list {
      padding-left: 1rem;
      padding-right: 1rem;
      border: solid 1px ${theme.colors.overLight.white90};
      border-radius: ${px4};
      min-height: 1rem;
      max-height: ${px170};
      overflow: hidden auto;

      .pool-list__item {
        margin-top: 1rem;
        margin-bottom: 1rem;

        span.label-content {
          font-size: ${px14};
          line-height: 1.25rem;
          letter-spacing: -0.1px;
          margin-left: 2rem;
          font-weight: ${theme.fonts.weights.semireg};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:not(.is-disabled) {
            color: ${theme.colors.overLight.white20};
          }
        }
      }
    }
  }
`

interface ILeavePoolModalProps extends IGenericEntryModalProps {
  title?: string
  close: () => void
  leavingPools: LeavePoolDataType
  poolData: TPoolRouteProps["poolData"]
}

const LeavePoolModal: FC<ILeavePoolModalProps> = ({ title = DEFAULT_TITLE, close, leavingPools, poolData, ...rest }) => {
  const [leavePools, { loading }] = useMutation<LeavePools, LeavePoolsVariables>(LEAVE_POOLS_MUTATION)
  const [leavingPoolsSelection, setLeavingPoolsSelection] = useState<Array<string>>([])
  const clearLeavingPools = () => setLeavingPoolsSelection([])
  const isPlural = leavingPoolsSelection.length > 1
  const ctaLabel = `Leave Pool${isPlural ? "s" : ""}`

  const togglePoolSelection = useCallback(
    (key: string) => {
      if (!leavingPoolsSelection.includes(key)) {
        setLeavingPoolsSelection([...leavingPoolsSelection, key])
      } else {
        setLeavingPoolsSelection(leavingPoolsSelection.filter((k) => k !== key))
      }
    },
    [leavingPoolsSelection],
  )

  const handleRemoveEntries = () => {
    const variables = {
      poolIds: leavingPoolsSelection,
      userId: poolData.allCurrentEntries?.[0].userId ?? "current",
    }

    leavePools({
      variables,
      refetchQueries: [{ query: CENTRAL_CURRENT_USERS_ENTRIES_QUERY, variables: poolData.gameDataVariables }],
      awaitRefetchQueries: true,
      update: (cache) => {
        const userTeamsKey = defaultDataIdFromObject({ __typename: "UserTeams", id: "current" })
        cache.evict({ id: userTeamsKey })
      },
    })
      .then(({ data }) => {
        const leftPools = data?.leavePools.length ?? 0
        const isPlural = leftPools === 0 || leftPools > 1
        toast.snackbar(`You have left ${leftPools} pool${isPlural ? "s" : ""}`)
        close()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const poolItems = Object.keys(leavingPools).map((key) => {
    const current = leavingPools[key]
    return (
      <div className="pool-list__item" key={key}>
        <Checkbox
          name={`leavePool-${key}`}
          label={current.name}
          disabled={!current.canLeavePool}
          isChecked={leavingPoolsSelection.includes(current.id)}
          onChange={() => togglePoolSelection(current.id)}
        />
      </div>
    )
  })

  const actions = (
    <>
      <Button variant="secondary" type="button" onClick={close} disabled={loading}>
        Cancel
      </Button>
      <Button
        variant="primary"
        type="button"
        disabled={leavingPoolsSelection.length <= 0}
        onClick={handleRemoveEntries}
        withLoading
        loading={loading}
      >
        {ctaLabel}
      </Button>
    </>
  )

  return (
    <GenericEntryModal
      onBackgroundClick={noop}
      onEscapeKeydown={noop}
      title={title}
      actions={actions}
      actionProps={{ dense: true }}
      afterClose={clearLeavingPools}
      {...rest}
    >
      <LeavePoolWrapper>
        <div className="modal__text--body1">{LIST_HEADER_TEXT}</div>
        <div className="modal__row--pool-list">
          <div className="pool-list">{poolItems}</div>
        </div>
        {LIST_FOOTER_TEXT.map((t, i) => {
          return (
            <div key={`leave-modal-${i}`} className="modal__text--body2">
              {t}
            </div>
          )
        })}
        <div className="modal__text--body2 modal__settings-link">
          *Want to remove pool from lobby view?{" "}
          <a href={manageSPOEURL} rel="noreferrer" target="_blank">
            Click here
          </a>{" "}
          to archive.
        </div>
      </LeavePoolWrapper>
    </GenericEntryModal>
  )
}

export default LeavePoolModal

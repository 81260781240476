import React, { FC, useContext, useCallback } from "react"
import { useMutation } from "@apollo/client"
import styled from "styled-components"
import { IModalProps } from "@cbs-sports/sports-shared-client/build/cjs/components/Modal/Modal.types"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { REMOVE_ENTRY_MUTATION } from "../../../queries"
import { RemoveEntryMutation, RemoveEntryMutationVariables } from "../../../../../__generated__/RemoveEntryMutation"
import { extractValidationError } from "../../../../components/Form"
import PoolDataContext from "../../../../Contexts/PoolDataContext"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import GenericConfirmationModal from "./GenericConfirmationModal"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { CENTRAL_CURRENT_USERS_ENTRIES_QUERY } from "../../../../utils/central-queries"

const DEFAULT_TITLE = "Delete bracket?"
const DEFAULT_MESSAGE = "You can’t undo this action."
const Errors = styled.span`
  margin-top: 2rem;
  font-weight: ${bracketTheme.fonts.weights.regular};
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.1px;
  color: ${bracketTheme.colors.overLight.red};
`

interface IDeleteBracketModalProps extends IModalProps {
  title?: string
  selectedEntry?: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node
  message?: string
  close?: () => void
  onEntryRemoved?: () => void
}

const DeleteBracketModal: FC<IDeleteBracketModalProps> = ({
  title = DEFAULT_TITLE,
  selectedEntry,
  message = DEFAULT_MESSAGE,
  close,
  isOpen,
  onEntryRemoved,
  ...rest
}) => {
  const poolData = useContext(PoolDataContext)
  const { detailedEntry: entry, gameDataVariables } = poolData || emptyObject

  const [removeEntryMutation, { loading, error }] = useMutation<RemoveEntryMutation, RemoveEntryMutationVariables>(REMOVE_ENTRY_MUTATION, {
    refetchQueries: [
      { query: CENTRAL_CURRENT_USERS_ENTRIES_QUERY, variables: gameDataVariables },
      "PoolSeasonStandingsQuery",
      "CentralCurrentUsersEntriesQuery",
    ],
    awaitRefetchQueries: true,
  })

  const handleDelete = () => {
    async function removeEntry() {
      const variables: RemoveEntryMutationVariables = {
        entryId: selectedEntry?.id || entry?.id || "",
      }
      await removeEntryMutation({ variables })
      close?.()
      onEntryRemoved?.()
    }
    removeEntry()
  }

  const displayErrorFn = useCallback(() => {
    const apiErrors = error && extractValidationError(error)
    return apiErrors?.errors?.base?.[0]
  }, [error])

  const displayError = displayErrorFn()
  return (
    <GenericConfirmationModal
      {...rest}
      isOpen={isOpen}
      onConfirm={handleDelete}
      onClose={close}
      title={title}
      message={message}
      ctaButtonLabel="Delete"
      loading={loading}
      additionalContent={displayError && <Errors>{displayError}</Errors>}
    />
  )
}

export default DeleteBracketModal

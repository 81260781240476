import React, { useEffect } from "react"
import styled from "styled-components"
import { pxToRem, newPalette, buildClassNames } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import GamePromoItem from "./GamePromoItem"
import { ProductInfoType } from "../../../../hooks/useMainPromoInfo"
import PrizePromoItem from "./PrizePromoItem"
import { NISSAN_PRIZE_IMAGE_PATH, PrizeLogo, ProductLogo } from "./MainPromo"

const MainPromoWrapper = styled.div`
  background-color: ${newPalette.white};
  background-image: url("/static/sport-assets/basketball/pool-setup-bg.svg");
  background-size: cover;
  border-radius: ${pxToRem(8)};
  border: solid 1px ${newPalette.neutral10};

  .promo__title {
    color: #142633;
    font-weight: 900;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: -0.25px;
    text-align: center;
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
  }

  .promo__rules {
    padding: 0 0.75rem;
    font-size: ${pxToRem(10)};
    line-height: ${pxToRem(18)};
    text-align: right;
    letter-spacing: -0.1px;
    color: ${newPalette.gray40};

    a {
      text-decoration: underline;
    }
  }

  .promo__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;

    .promo__item--first {
      order: 1;
    }

    .promo__item--second {
      order: 2;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 1px;
        height: ${pxToRem(106)};
        background-color: ${newPalette.neutral20};
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 1px;
        height: ${pxToRem(106)};
        background-color: ${newPalette.neutral20};
      }
    }

    .promo__item--third {
      order: 3;
    }
  }
`
const TITLE = "Play for Prizes"
interface MainPromoRootProps {
  ncaabInfo: ProductInfoType
  ncaawInfo: ProductInfoType
  ncaabCTANode: JSX.Element
  ncaawCTANode: JSX.Element
  prizeUnlocked: boolean
  rulesUrl: string
  impressionTracking: () => void
}
const MainPromoRoot: React.FC<MainPromoRootProps> = ({
  ncaabInfo,
  ncaawInfo,
  ncaabCTANode,
  ncaawCTANode,
  prizeUnlocked,
  rulesUrl,
  impressionTracking,
}) => {
  useEffect(() => {
    impressionTracking?.()
  }, [impressionTracking])
  return (
    <MainPromoWrapper>
      <h1 className="promo__title">{TITLE}</h1>
      <div className="promo__container">
        <GamePromoItem
          className={buildClassNames({
            "promo__item--first": ncaabInfo.order === 1,
            "promo__item--second": ncaabInfo.order === 2,
          })}
          data={ncaabInfo}
          logoNode={<ProductLogo width={100} src="/static/game-assets/cbs-ncaab-tournament/final_four.svg" alt="NCAAB Final Four logo" />}
          ctaNode={ncaabCTANode}
          data-cy="promo-ncaab"
        ></GamePromoItem>
        <GamePromoItem
          className={buildClassNames({
            "promo__item--first": ncaawInfo.order === 1,
            "promo__item--second": ncaawInfo.order === 2,
          })}
          data={ncaawInfo}
          logoNode={<ProductLogo width={100} src="/static/game-assets/cbs-ncaaw-tournament/final_four.svg" alt="NCAAW Final Four logo" />}
          ctaNode={ncaawCTANode}
          data-cy="promo-ncaaw"
        ></GamePromoItem>
        <PrizePromoItem
          className="promo__item--third"
          status={prizeUnlocked ? "UNLOCKED" : "LOCKED"}
          logoNode={<PrizeLogo src={NISSAN_PRIZE_IMAGE_PATH} alt="Nissan Prize" style={{ height: "7rem", marginBottom: "0.5rem" }} />}
          data-cy="promo-prize"
        />
      </div>
      <div className="promo__rules">
        No Purchase Necessary -{" "}
        <a href={rulesUrl} rel="nofollow noopener noreferrer" target="_blank">
          See Official Rules
        </a>
      </div>
    </MainPromoWrapper>
  )
}

export default MainPromoRoot

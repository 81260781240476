import React from "react"
import { IButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import Link from "../../../../components/Link"

type ButtonProps = Pick<IButton, "loading" | "disabled" | "onClick" | "variant">

type CTAProps = {
  copy: string
  to?: string
  triggerTracking?: () => void
}

export type GamePromoCTAProps = CTAProps & Partial<ButtonProps>

const GamePromoCTA: React.VFC<GamePromoCTAProps> = ({ copy, to, onClick, loading, disabled, triggerTracking, variant = "clear" }) => {
  if (!!to) {
    return (
      <StyledButton className={`variant--${variant}`} as={Link} to={to} onClick={triggerTracking}>
        {copy}
      </StyledButton>
    )
  }

  return (
    <Button
      variant={variant}
      withLoading
      loading={loading}
      onClick={(e) => {
        if (triggerTracking) {
          triggerTracking()
        }
        if (onClick) {
          onClick(e)
        }
      }}
      disabled={disabled}
    >
      {copy}
    </Button>
  )
}

export default GamePromoCTA

import styled from "styled-components/macro"
import { palette, fontSizes, gridBreakPoints, fontWeight } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { ButtonWithAfterContentInteraction } from "../../../../../components/Button"

const { queries } = gridBreakPoints
export const Wrapper = styled(ButtonWithAfterContentInteraction)`
  display: flex;
  flex-direction: row;
  background-color: ${palette.white};
  box-shadow: none;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid ${palette.gray90};
  margin: 0.5rem 0 0;
  border-radius: 0.25rem;
  width: 100%;
  align-items: center;
  height: 3.5rem;
  & > div.text-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    color: ${palette.gray40};
    line-height: 1rem;
    letter-spacing: -0.1px;
    & svg {
      color: ${palette.lightBlue3};
      margin-right: 1rem;
    }
  }

  & > div.btn-content {
    font-style: normal;
    font-size: ${fontSizes.regular};
    font-weight: ${fontWeight.medium};
    line-height: 1rem;
    letter-spacing: 0.25px;
    text-align: right;
    color: ${palette.lightBlue3};

    & > span.mobile-content--true {
      display: flex;
      margin-right: -0.5rem;
      & > svg {
        width: 1.5rem;
        height: 1.5rem;
        color: ${palette.gray50};
      }
    }
    & > span.mobile-content--false {
      display: none;
    }
  }

  ${queries.sm} {
    padding: 0.75rem 1rem;
    margin: 0.75rem 1rem 0;
    margin: 0.5rem 0 0;
    height: 3rem;
    & > div.text-wrapper {
      display: flex;
      margin-bottom: 0;
    }

    & > div.btn-content {
      & > span.mobile-content--true {
        display: none;
      }
      & > span.mobile-content--false {
        display: flex;
      }
    }
  }

  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: ${palette.lightestBlue};
    }
  }
`
export const CreateBracketText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSizes.regular};
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
`

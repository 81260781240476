import React from "react"
import { Redirect } from "react-router"
import { withoutDomain } from "../../../../common/url-utils"
import { TPoolRouteProps } from "../../../../routes.d"
import Status from "../../../Base/Status"
import LoadingView from "../../../components/LoadingView"
import MultipleEntriesLobby from "../components/MultipleEntriesLobby"

function Games(props: TPoolRouteProps) {
  const { poolData } = props
  const { segmentForArea, hasOnlyAvailableEntryForSport, allSegments } = poolData
  const challengePoolPath = (segmentForArea && withoutDomain(segmentForArea.landingUrl)) || ""
  if (hasOnlyAvailableEntryForSport && challengePoolPath) {
    return (
      <Status code={302}>
        <Redirect to={challengePoolPath} />
      </Status>
    )
  }
  if (!allSegments.length) {
    return <LoadingView className="lobby" />
  }
  return <MultipleEntriesLobby {...props} />
}

export default Games

import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { palette, pxToRem, fontWeights, gridBreakPoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { setParam } from "@cbs-sports/sports-shared-client/build/cjs/utils/url-utils"
import { ProductInfoType } from "../../../../hooks/useMainPromoInfo"
import { useHistory, useLocation } from "react-router"
import { getAuthSearch, withoutDomain } from "../../../../utils/url-utils"
import { useDeviceType } from "../../../../Base/DeviceType"

import Analytics from "../../../../utils/analytics"
import UpsertEntryNameModal from "../../../PoolPages/components/EntryModals/UpsertEntryNameModal"
import Link from "../../../../components/Link"
import JoinedToPoolModal from "../../../PoolPages/components/EntryModals/JoinedToPoolModal"
import constants from "../../../../../common/constants"

const { queries } = gridBreakPoints
const DEFAULT_HEADER = "ENTER A BRACKET, WIN BIG!"

const BigStyledAddBracketComponent = styled.div`
  position: relative;
  background-color: ${palette.white};
  background-image: url(/static/sport-assets/basketball/pool-setup-bg.svg);
  background-repeat: repeat-x;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .logosContainer {
    padding: 2rem 1.5rem;
    flex: 0 0 auto;

    img {
      max-width: ${pxToRem(150)};
    }
  }

  .promoContent {
    flex: 1;
    text-align: center;
  }

  .promoText1 {
    color: ${palette.gray20};
    font-size: ${pxToRem(18)};
    font-weight: ${fontWeights.xxbold};
    letter-spacing: -0.1px;
    margin-bottom: 0.5rem;
  }

  .promoText2 {
    color: ${palette.gray40};
    font-size: ${pxToRem(14)};
    letter-spacing: -0.1px;
    line-height: 20px;
  }

  .ctaWrapper {
    flex: 0 0 auto;
    padding-top: 1rem;
    align-self: stretch;

    & > button {
      width: 100%;
    }
  }

  &.use-as-component--true {
    border: 1px solid ${palette.gray90};
  }

  ${queries.sm} {
    .container {
      flex-direction: row;
      padding: 0;
    }

    .promoContent {
      text-align: left;
    }

    .ctaWrapper {
      padding: 2rem 1.5rem;
      align-self: center;
    }

    &.use-as-component--true {
      border-radius: 0.5rem;
    }

    & div.promoText1 {
      font-size: ${pxToRem(32)};
      line-height: ${pxToRem(32)};
    }

    & div.promoText2 {
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(18)};
    }
  }
`

const BigBpcDisclaimerWrapper = styled.div`
  font-size: ${pxToRem(10)};
  line-height: ${pxToRem(18)};
  letter-spacing: -0.1px;
  color: ${palette.gray40};
  text-align: right;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  a.disclaimerRulesLink {
    text-decoration: underline;
  }
`

type SingleGamePromoItemProps = {
  rulesUrl: string
  copy: string
  isLoggedIn: boolean
  info: ProductInfoType
  header?: string
  logoNode?: React.ReactNode
  useAsComponent?: boolean
  isFromLobby?: boolean
  impressionTracking?: () => void
}

const SingleGamePromoItem: React.VFC<SingleGamePromoItemProps> = ({
  rulesUrl,
  copy,
  isLoggedIn,
  info,
  logoNode,
  header = DEFAULT_HEADER,
  useAsComponent = true,
  isFromLobby = true,
  impressionTracking,
}) => {
  const location = useLocation()
  const history = useHistory()
  const isInPreTournament = info.isPreTournament
  const deviceType = useDeviceType()
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    impressionTracking?.()
  }, [impressionTracking])

  const hideModal = (created = false, entryUrl: string | undefined = undefined) => {
    const { pathname } = location
    setShowModal(false)
    if (isFromLobby && created) {
      if (isInPreTournament) {
        setTimeout(() => {
          history.push({
            pathname: info.lobbyUrl,
            state: {
              modal: {
                modalId: JoinedToPoolModal.modalKey,
              },
            },
          })
        }, 1000)
      } else if (entryUrl) {
        history.push(withoutDomain(entryUrl))
      }
    } else {
      history.replace(pathname)
    }
  }

  const trackClickAction = (gameInstanceUid: string) => {
    switch (gameInstanceUid) {
      case constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID:
      case constants.NISSAN_NCAAB_CHALLENGE_GAME_INSTANCE_UID:
        // mens-challenge
        Analytics.trackAction("challenge entry", "lobby banner", "bpc_join now")
        break
      case constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID:
      case constants.NISSAN_NCAAW_CHALLENGE_GAME_INSTANCE_UID:
        // womens-challenge
        Analytics.trackAction("challenge entry", "lobby banner", "wbpc_join now")
        break
      case constants.NCAAW_MANAGER_GAME_INSTANCE_UID:
        // womens-challenge
        Analytics.trackAction("brackets welcome", "picking options", "wbpm create bracket")
        break
      default:
        // manager
        Analytics.trackAction("brackets welcome", "picking options", "create bracket")
        break
    }
  }

  const handleBPCCreateBracket = useCallback(() => {
    trackClickAction(info.gameInstanceUid)
    if (deviceType === "handheld") {
      const roadblockUrl = `/games/roadblock?showCongrats=false&from=lobby&gameType=${info.gameInstanceUid}`
      history.push(withoutDomain(roadblockUrl))
    } else {
      setShowModal(true)
    }
  }, [deviceType, history, info.gameInstanceUid])

  let to: any
  if (!isLoggedIn && isInPreTournament) {
    const { pathname, search: currentSearch } = location
    const newSearch = setParam(currentSearch, "modalId", UpsertEntryNameModal.modalKey)
    const search = getAuthSearch(
      `${pathname}?${newSearch ?? ""}`,
      info.productAbbrev,
      // productSeason?.masterProductId,
      // isCbsAppWebview,
    )
    to = {
      pathname: "/auth/login",
      search,
    }
  } else if (!(isLoggedIn && isInPreTournament)) {
    to = `${info.lobbyUrl}/challenge`
  }
  const logos = deviceType !== "handheld" && <div className="logosContainer">{logoNode}</div>
  const addBracketText = isInPreTournament ? "Join Now" : "Create Bracket"
  let ctaButton = (
    <StyledButton onClick={handleBPCCreateBracket} className="variant--primary" data-cy="single-game-enter-bracket-btn2">
      {addBracketText}
    </StyledButton>
  )
  if (to) {
    ctaButton = (
      <StyledButton className="variant--primary" as={Link} to={to} data-cy="single-game-enter-bracket-btn">
        Enter Bracket
      </StyledButton>
    )
  }

  const challlengeModal = (
    <UpsertEntryNameModal
      isOpen={showModal}
      redirectToLobby
      title="Name Your Bracket"
      close={hideModal}
      gameInstanceUid={info.gameInstanceUid}
      isCommingSoon={isInPreTournament}
      mode="bpc"
      seasonType={info.seasonType}
      year={info.seasonYear}
      productAbbrev="bpc"
    />
  )

  return (
    <>
      {challlengeModal}
      <BigStyledAddBracketComponent className={`use-as-component--${useAsComponent}`}>
        <div className="container">
          {logos}
          <div className="promoContent">
            <div className="promoText1">{header}</div>
            <div className="promoText2">{copy}</div>
          </div>
          <div className="ctaWrapper">{ctaButton}</div>
        </div>
        <BigBpcDisclaimerWrapper>
          <span data-cy="single-game-no-purchase-txt">
            No Purchase Necessary -{" "}
            <a
              className="disclaimerRulesLink"
              href={rulesUrl}
              target="_blank"
              rel="noopener nofollow noreferrer"
              data-cy="single-game-no-purchase-link"
            >
              See Official Rules
            </a>
          </span>
        </BigBpcDisclaimerWrapper>
      </BigStyledAddBracketComponent>
    </>
  )
}

export default SingleGamePromoItem

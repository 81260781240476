import React, { FC, useMemo, ReactElement } from "react"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import { pxToRem, palette, fontWeight } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { FANTASY_ARTICLES_QUERY } from "../../queries"
import { FantasyArticlesQuery, FantasyArticlesQueryVariables } from "../../../../__generated__/FantasyArticlesQuery"
import { GameSportTypeEnumType } from "../../../../__generated__/globalTypes"
import { emptyArray } from "../../../../common/misc-utils"
import FantasyArticle from "../components/FantasyArticle"
import { getNewsSearchSlugFromSportType } from "../../../utils/data-utils"
import LoadingView from "@cbs-sports/sports-shared-client/build/cjs/components/LoadingView"
import { SportTypesEnum } from "../../../../common/enums"
import { registeredTrademark } from "../../../../common/constants"

const ARTICLE_COUNT_LIMIT = 10
const px20 = pxToRem(20)

const ArticlesSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
  padding: 1.25rem 1.5rem 1.5rem 1.5rem;
  border: 1px solid #dae0e5;
  border-radius: 0.5rem;
  & > .section-title {
    padding-bottom: 1.25rem;
    font-weight: ${fontWeight.bold};
    font-size: ${px20};
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid ${palette.gray90};
    color: ${palette.gray20};
  }
`

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface IProps {
  sportType: GameSportTypeEnumType
  loadMoreComponent?: ReactElement
}

const getTitle = (sportType: GameSportTypeEnumType) => {
  let title = ""
  switch (sportType) {
    case SportTypesEnum.NCAAB:
      title = `LATEST NCAA${registeredTrademark} MEN'S TOURNAMENT NEWS`
      break
    case SportTypesEnum.NCAAW:
      title = `LATEST NCAA${registeredTrademark} WOMEN'S TOURNAMENT NEWS`
      break
    default:
      title = ""
      break
  }
  return title
}
const LatestNews: FC<IProps> = ({ sportType, loadMoreComponent }) => {
  const searchSlug = getNewsSearchSlugFromSportType(sportType)
  const variables: FantasyArticlesQueryVariables = { sportType, searchSlug, limit: ARTICLE_COUNT_LIMIT }
  const { data, loading } = useQuery<FantasyArticlesQuery, FantasyArticlesQueryVariables>(FANTASY_ARTICLES_QUERY, { variables })
  const title = useMemo(() => getTitle(sportType), [sportType])
  const articlesSection = useMemo(() => {
    const articleData = data?.fantasyArticles ?? emptyArray
    if (!articleData.length) {
      return null
    }
    const articles = articleData.map((articleDataItem) => <FantasyArticle key={articleDataItem.id} data={articleDataItem} sportType={sportType} />)

    return (
      <ArticlesSection>
        {title && (
          <header className="section-title" data-cy="news-header">
            {title}
          </header>
        )}
        {articles}
      </ArticlesSection>
    )
  }, [data, sportType, title])

  if (loading) {
    return <LoadingView color={palette.curiousBlue} />
  }
  if (!articlesSection) {
    return null
  }
  const loadMore = Boolean(loadMoreComponent) ? <LoadMoreWrapper>{loadMoreComponent}</LoadMoreWrapper> : null

  return (
    <div>
      {articlesSection}
      {loadMore}
    </div>
  )
}

export default React.memo(LatestNews)

import React, { useState, useCallback, useContext, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import { PoolRowWrap, EntryRowHeader, PoolName } from "./PoolRow.styles"
import { IPoolRowProps } from "../MyGroups.types"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../../../__generated__/CentralCurrentUsersEntriesQuery"
import AddEntryRow from "../AddEntryRow"
import EntryRow from "../../../containers/EntryRow"
import { withoutDomain } from "../../../../../../common/url-utils"
import ChevDownSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ChevDown"
import { ListItemIcon } from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import { StarSVG } from "../../../../../components/icons"
import { ENUM_MANAGER } from "../../../../../../common/enums"
import { formatMembersCount } from "../../../../../utils/data-utils"
import { useDeviceType } from "../../../../../Base/DeviceType"
import DeleteBracketModal from "../../EntryModals/DeleteBracketModal"
import Analytics from "../../../../../utils/analytics"
import PoolDataContext from "../../../../../Contexts/PoolDataContext"
import { emptyObject, NCAAB_S16_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import constants from "../../../../../../common/constants"

const PoolRow = (props: IPoolRowProps) => {
  const poolData = useContext(PoolDataContext)
  const { isInComingSoon, centralBracketState } = poolData || emptyObject
  const history = useHistory()

  const [modal, setModal] = useState<string | null>(null)
  const [selectedEntry, setSelectedEntry] = useState<CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node | undefined>(undefined)
  const deviceType = useDeviceType()
  const { entries } = props

  const handleEntryRemoveConfirmation = useCallback(() => {
    toast.snackbar("Your bracket has been deleted")
  }, [])

  if (!entries?.length) {
    return null
  }
  const [first] = entries
  const {
    pool: { name, entriesCount, id, avatarUrl, url, canAddEntry, gameInstanceUid = "" },
    roles,
    canDeleteEntry,
  } = first

  const isSweet16Group = constants.SWEET_16_ENABLED && first.gameInstanceUid === NCAAB_S16_GAME_INSTANCE_UID
  const isLocked = !!(isSweet16Group
    ? false // centralBracketState?.bracketState?.sweetSixteenBracketState.isLocked
    : centralBracketState?.bracketState?.isLocked)

  const isChallengePool = !gameInstanceUid.match(/manager/)
  const showRedMark = !isInComingSoon && !isLocked
  const allBracketsComplete = entries.reduce((result, item) => {
    const { totalPicksCount, maxPicksCount } = item
    return result && !item.needsToMakePicks && totalPicksCount === maxPicksCount
  }, true)

  const idFor = `pool-${id}`
  const entryHeight = 56
  const count = canAddEntry ? entries.length + 1 : entries.length
  const contentHeight = 40 + count * entryHeight
  const hasManagerRole = roles.indexOf(ENUM_MANAGER) > -1

  const hideModal = () => {
    setModal(null)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleDeleteClick = useCallback(
    (entry, event: any) => {
      event.preventDefault()
      event.stopPropagation()
      Analytics.trackAction("brackets welcome", "picking options", "delete bracket")
      setSelectedEntry(entry)
      setModal("remove-bracket")
    },
    [setModal, setSelectedEntry],
  )
  const { poolIds } = history.location.state?.["saveBracketInfo"] || {}
  const key = history.location.state?.["key"] || undefined

  const checkValue = Array.isArray(poolIds) && poolIds.indexOf(id) >= 0
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const entriesToRender = useMemo<CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[]>(
    () =>
      isLocked
        ? entries.sort((a, b) => {
            const aRank = a.poolRank || 9999
            const bRank = b.poolRank || 9999
            return aRank === bRank ? a.createdAt - b.createdAt : bRank - aRank
          })
        : entries,
    [isLocked, entries],
  )
  return (
    <PoolRowWrap contentHeight={contentHeight} key={key}>
      <input type="checkbox" name="pool-check" id={idFor} defaultChecked={checkValue} />
      <label htmlFor={idFor} className="accordion-header">
        <div className="left-side">
          <div className="logo-wrap">
            <div className="logo">
              <img src={avatarUrl} alt="Pool Logo" />
            </div>
            {hasManagerRole && (
              <div className="pool-manager-info">
                <ListItemIcon size="sm">
                  <StarSVG />
                </ListItemIcon>
              </div>
            )}
          </div>
          <div className="pool-information">
            <PoolName
              as={Link}
              className="pool-name"
              to={
                deviceType !== "handheld"
                  ? `${withoutDomain(url)}/standings`
                  : withoutDomain(`/games/roadblock?showCongrats=false&from=lobby&gameType=${gameInstanceUid}`)
              }
              data-cy="pool-name"
            >
              {name}
            </PoolName>
            {!isChallengePool && <span className="pool-members-count">{formatMembersCount(entriesCount, "Bracket")}</span>}
          </div>
        </div>
        <div className="right-side">
          {!allBracketsComplete && showRedMark && <div className="incomplete-brackets" />}
          <span className="brackets-count">{`My Brackets (${entries.length})`}</span>
          <div className="chevron-icon">
            <ChevDownSvg />
          </div>
        </div>
      </label>
      <div className="accordion-content">
        {!isLocked && canAddEntry && <AddEntryRow {...props} />}
        {entriesToRender.map((entry: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node) => {
          const { id } = entry
          const key = `entry-${id}`
          return (
            <EntryRow
              isLocked={isLocked}
              entry={entry}
              key={key}
              canDeleteEntry={canDeleteEntry}
              handleDeleteClick={handleDeleteClick}
              className="pool-row-entry"
            />
          )
        })}
        <EntryRowHeader>
          <span className="rank-title">rank</span>
          <span className="champion-title">champion</span>
          <span className="champion-name">bracket name</span>
          {!isLocked && <span className={cx({ "champion-status": true })}>status</span>}
          {isLocked && !canDeleteEntry && (
            <>
              <span className="stats-title">pts</span>
              <span className="stats-title">max</span>
            </>
          )}
        </EntryRowHeader>
      </div>
      <DeleteBracketModal
        close={hideModal}
        isOpen={modal === "remove-bracket"}
        selectedEntry={selectedEntry}
        onEntryRemoved={handleEntryRemoveConfirmation}
      />
    </PoolRowWrap>
  )
}

export default PoolRow

import React, { FC, useEffect } from "react"
import styled from "styled-components"
import StyledButton from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button.styles"
import GenericEntryModal, { IGenericEntryModalProps, GenericModalComponentProps } from "./GenericEntryModal"
import { IPickUtilsTeam } from "@cbs-sports/sports-shared-client/build/cjs/utils/common-utils-types"
import constants, {
  NCAAB_CHALLENGE_IMPRESSION_URL_SRC,
  NCAAW_CHALLENGE_IMPRESSION_URL_SRC,
  registeredTrademark,
} from "../../../../../common/constants"
import { useDeviceType } from "../../../../Base/DeviceType"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import useMainPromoInfo from "../../../../hooks/useMainPromoInfo"
import { getRulesLink } from "../../../../../common/game-text"
import { isNissanMarchMadnessMatcher } from "../../../../../common/common-utils-helpers"
import Analytics from "../../../../utils/analytics"
import OnSavedPicksModal, { ICrossSellInfo } from "@cbs-sports/sports-shared-client/build/cjs/components/Brackets/OnSavedPicksModal/OnSavedPicksModal"
import { ENUM_NCAAB, ENUM_NCAAW } from "../../../../../common/enums"
import OneByOneAd from "../OneByOneAd"

const logoUrl = `${constants.DOMAIN}/static/cbs-sports-app.png`
const BRANCH_LINK = "https://cbs-sports.app.link/naPINwV6Ydb"

const SavedPicksConfirmationRoot = styled.div`
  padding: 0;
  overflow: hidden;
`
const CONTENT_WRAPPER_PROPS: GenericModalComponentProps = { as: SavedPicksConfirmationRoot }

interface ISavedPicksConfirmationModalProps extends IGenericEntryModalProps {
  close: () => void
  isBracketComplete: boolean
  lockTime: number
  team?: IPickUtilsTeam
  gameInstanceUid?: string
  logo?: React.ReactNode
  isChallengeEntry?: boolean
}

const PRESENTED_BY_ONE_BY_ONES = {
  NCAAW: NCAAW_CHALLENGE_IMPRESSION_URL_SRC,
  NCAAB: NCAAB_CHALLENGE_IMPRESSION_URL_SRC,
}

const SavedPicksConfirmationModal: FC<ISavedPicksConfirmationModalProps> = ({
  isOpen,
  isBracketComplete,
  team,
  lockTime,
  close,
  gameInstanceUid,
  logo: teamLogo,
  isChallengeEntry,
}) => {
  const deviceType = useDeviceType()
  const { ncaabInfo, ncaawInfo, isInNcaawLobby, rulesUrl } = useMainPromoInfo()
  const isMobile = deviceType === "handheld"
  const logo = <img alt="CBS Logo" src={logoUrl} />

  let crossSellInfo: ICrossSellInfo | null = null
  let pageViewTrackingKey = ""
  if (isBracketComplete && isChallengeEntry && !isNissanMarchMadnessMatcher.test(gameInstanceUid || "")) {
    // after saving picks in women's challenge, show bpc promo in champion modal if bpc pickable and user doesn't have an entry yet
    if (isInNcaawLobby && !ncaabInfo.isPreTournament && !ncaabInfo.isLocked && !ncaabInfo.numberOfEntries) {
      crossSellInfo = {
        productAbbrev: ncaabInfo.productAbbrev || "bpc",
        promoCopy: `Enter a men’s bracket for the chance to WIN A NEW SUV and a trip to the ${
          constants.MARCH_MADNESS_SPORT_YEAR + 2
        } Men’s Final Four${registeredTrademark}!`,
        ctaCopy: "Enter to win",
        ctaLink: `${ncaabInfo.lobbyUrl}/challenge?ftag=CBS-16-10ach6j`,
        ctaTracking: () => Analytics.trackAction("brackets interstitial", "womens bracket challenge", "enter now"),
        rulesLink: rulesUrl,
      }
      pageViewTrackingKey = "wbpc-cross-promo"
    }
    // after saving picks in mens's challenge, show wbpc promo in champion modal if wbpc pickable and user doesn't have an entry yet
    else if (!ncaawInfo.isPreTournament && !ncaawInfo.isLocked && !ncaawInfo.numberOfEntries) {
      crossSellInfo = {
        productAbbrev: ncaawInfo.productAbbrev || "wbpc",
        promoCopy: isMobile
          ? "Edit your bracket, check standings on the go, and Play Women’s Challenge for a chance to win more prizes."
          : `Enter a women’s bracket for the chance to WIN A NEW SUV and a trip to the ${
              constants.MARCH_MADNESS_SPORT_YEAR + 2
            } Women’s Final Four${registeredTrademark}!`,
        ctaCopy: isMobile ? "Open the App" : "Enter to win",
        ctaLink: isMobile ? BRANCH_LINK : `${ncaawInfo.lobbyUrl}/challenge?ftag=CBS-16-10ach6i`,
        ctaTracking: () => Analytics.trackAction("brackets interstitial", "mens bracket challenge", "enter now"),
        rulesLink: getRulesLink(
          "cbs-ncaaw-tournament-challenge",
          ncaawInfo.seasonType || "",
          ncaawInfo.productAbbrev || "bpc",
          ncaawInfo.seasonYear || new Date().getFullYear(),
          "disclaimer",
        ),
      }
      pageViewTrackingKey = "bpc-cross-promo"
    }
  }
  useEffect(() => {
    if (isOpen && pageViewTrackingKey) {
      Analytics.trackAction(
        "brackets interstitial",
        pageViewTrackingKey === "bpc-cross-promo" ? "mens bracket challenge" : "womens bracket challenge",
        "",
        "impression",
      )
    }
  }, [isOpen, pageViewTrackingKey])
  const ctaNode = React.useMemo(() => {
    return (
      <StyledButton className="is-full-width--true variant--primary" as="a" href={BRANCH_LINK}>
        Open The App
      </StyledButton>
    )
  }, [])
  // one by one for the presented by Nissan svg
  const oneByOneUrl = isChallengeEntry && !crossSellInfo ? PRESENTED_BY_ONE_BY_ONES[isInNcaawLobby ? ENUM_NCAAW : ENUM_NCAAB] : ""
  const socialShareTracking = {
    fbTracking: () =>
      Analytics.trackAction("brackets interstitial", isInNcaawLobby ? "womens bracket challenge" : "mens bracket challenge", "share - facebook"),
    twitterTracking: () =>
      Analytics.trackAction("brackets interstitial", isInNcaawLobby ? "womens bracket challenge" : "mens bracket challenge", "share - twitter"),
  }
  return (
    <>
      {isOpen && (
        <>
          <AnalyticScreen
            feature="brackets"
            subfeature={`success`}
            title={`Picks Saved`}
            gameInstanceUid={gameInstanceUid}
            isModal={true}
            deviceType={deviceType}
          />
          {oneByOneUrl && <OneByOneAd impressionUrl={oneByOneUrl} />}
        </>
      )}
      <GenericEntryModal
        modalType={isMobile ? "takeover" : "modal"}
        isOpen={isOpen}
        padded={false}
        onBackgroundClick={close}
        onEscapeKeydown={close}
        modalContentWrapperProps={CONTENT_WRAPPER_PROPS}
      >
        <OnSavedPicksModal
          isMobile={isMobile}
          isBracketComplete={isBracketComplete}
          team={team}
          lockTime={lockTime}
          handleCancelClick={close}
          appLinkSectionProps={{ appLinkBoxProps: { logo, cta: ctaNode } }}
          logo={teamLogo}
          isChallengeEntry={isChallengeEntry}
          crossSellInfo={crossSellInfo}
          socialShareTracking={socialShareTracking}
        />
      </GenericEntryModal>
    </>
  )
}

export default SavedPicksConfirmationModal

import React, { FC } from "react"
import { fontFamily, fontSizes, fontWeight, palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import A from "../../../components/A"
import styled from "styled-components"
import { teamImgUrl } from "../../../utils/data-utils"
import { GameSportTypeEnumType } from "../../../../__generated__/globalTypes"

const getShieldLogoUrlFromSportType = (sportType: GameSportTypeEnumType) => {
  switch (sportType) {
    case "NFL":
      return "https://sports.cbsimg.net/fly/images/league-logos/59.svg"
    case "NCAAF":
    case "NCAAB":
    case "NCAAW":
      return "https://sports.cbsimg.net/fly/images/league-logos/56.svg" // NCAA Logo
    default:
      return undefined
  }
}

const px12 = pxToRem(12)

const Article = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  .promo-image-wrapper {
    flex-shrink: 0;
    img {
      width: ${pxToRem(213)};
      height: ${pxToRem(120)};
      border-radius: 0.25rem;
    }
    .promo-image-link {
      display: block;
    }
  }
  .information {
    margin-left: 1rem;
    .extra-info {
      display: flex;
      align-items: center;
      color: ${palette.gray50};
      font-family: ${fontFamily.condensed};
      font-weight: ${fontWeight.medium};
      font-size: ${px12};
      line-height: 1.25rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      & > .logo {
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
        margin-right: 0.5rem;
      }
    }
  }

  .promo-title {
    display: inline-flex;
    margin-bottom: 0.25rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
    font-weight: ${fontWeight.bold};
    color: ${palette.gray20};
    letter-spacing: -0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:hover {
      color: ${palette.lightBlue3};
    }
  }

  .description {
    color: ${palette.gray40};
    font-size: ${fontSizes.regular};
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

interface INewsArticle {
  id: string
  url: string
  promoTitle: string
  description: string
  promoImage: {
    path: string
  }
  author: {
    firstName: string
    lastName: string
  }
  wordCount: number
  metaData: {
    teamAbbreviation: string[]
    teamId: number[]
    teamCount: number
  }
}

interface IProps {
  data: INewsArticle
  sportType: GameSportTypeEnumType
}

const NewsArticle: FC<IProps> = ({
  data: {
    url,
    promoTitle,
    description,
    promoImage: { path },
    wordCount,
    metaData: { teamAbbreviation, teamId, teamCount },
  },
  sportType,
}) => {
  const readTimeInMinutes = Math.floor(wordCount / 200) >= 1 ? Math.floor(wordCount / 200) : 1
  const shieldLogoUrl = getShieldLogoUrlFromSportType(sportType)
  return (
    <Article>
      <div className="promo-image-wrapper">
        <A to={url} className="promo-image-link" target="_blank" rel="noopener noreferrer" data-cy="news-item">
          <img src={`${path}?width=426&height=240&fit=crop`} alt="Article promo" />
        </A>
      </div>
      <div className="information">
        <A to={url} className="promo-title" target="_blank" rel="noopener noreferrer">
          {promoTitle || ""}
        </A>
        <p className="description">{description || ""}</p>
        <div className="extra-info">
          {teamCount === 1 ? (
            <div className="logo">
              <img
                src={teamImgUrl({ sportType, abbrev: teamAbbreviation[0], id: teamId[0], cbsTeamId: teamId[0], location: "", nickName: "" })}
                alt={`${teamAbbreviation[0]} team logo`}
              />
            </div>
          ) : shieldLogoUrl ? (
            <div className="logo">
              <img src={shieldLogoUrl} alt={`${sportType} league logo`} />
            </div>
          ) : null}

          {wordCount && <span className="read-time">{readTimeInMinutes} MIN READ</span>}
        </div>
      </div>
    </Article>
  )
}

export default NewsArticle

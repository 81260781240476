import React, { FC, useState, useCallback } from "react"
import styled from "styled-components"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject, NCAAB_GAME_INSTANCE_UID, NCAAW_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import WarningAlert from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WarningAlert"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import RadioDualLineEntryRow from "../../containers/EntryRow/RadioDualLineEntryRow"
import useCookiePicksImport, { PicksImportType } from "../../../../hooks/useCookiePicksImport"
import UpsertEntryNameModal from "./UpsertEntryNameModal"
import PoolDataContext from "../../../../Contexts/PoolDataContext"
import { getStringParam } from "@cbs-sports/sports-shared-client/build/cjs/utils/url-utils"
import { useHistory, useLocation } from "react-router-dom"
import { useDeviceType } from "../../../../Base/DeviceType"
import { buildPickUtils } from "../../../../hooks/usePoolData"

const DEFAULT_TITLE = "Bracket Limit"
const WARNING_TEXT = "You’ve already submitted the maximum number of brackets allowed in this pool."
const ACTION_PROPS = { dense: true }
const px14 = pxToRem(14)
const px40 = pxToRem(40)

const BracketLimitModalRoot = styled.div<{ isMobile?: boolean }>`
  width: ${(props) => (props.isMobile ? "100%" : `calc(${pxToRem(488)} - ${pxToRem(80)})`)};

  .modal__warning-row {
    margin-bottom: 1.5rem;
    display: flex;
  }

  .modal__error-row {
    margin-top: 0.75rem;
  }

  .modal__warning-icon {
    width: ${px40};
    height: ${px40};
    color: ${theme.colors.overLight.red};
    margin-right: 1rem;
  }

  .modal__warning-text {
    font-size: ${px14};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    font-weight: ${theme.fonts.weights.bold};
    color: ${theme.colors.overLight.white20};
    min-width: 0;
    flex: 1;
  }

  .modal__entry-list {
    margin-top: 0.75rem;
    border: solid 1px ${theme.colors.overLight.white90};
    border-radius: 0.25rem;
    padding: 1rem;
    overflow: hidden auto;
  }

  .modal__entry-item {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  .label-content {
    margin-left: 2rem;
    min-width: 0;
  }
`

interface IBracketLimitModalProps extends IGenericEntryModalProps {
  close: () => void
  isSingleEntry?: boolean
}
type LimitedPicksImportType = Pick<PicksImportType, "challengeEntries" | "upsertEntry" | "upsertEntryResult">

const BracketLimitModal: FC<IBracketLimitModalProps & LimitedPicksImportType> = ({
  isOpen,
  title = DEFAULT_TITLE,
  close,
  challengeEntries,
  upsertEntry,
  upsertEntryResult,
  isSingleEntry = false,
  ...rest
}) => {
  const deviceType = useDeviceType()
  const isMobile = deviceType === "handheld"
  const [hasError, setHasError] = useState<boolean>(false)

  const [selection, setSelection] = useState<string | undefined>(() => {
    const [firstEntry] = challengeEntries
    return firstEntry?.id
  })

  const handleUpsertEntry = useCallback(() => {
    if (!selection) {
      return
    }
    setHasError(false)
    upsertEntry({ variables: { entryId: selection } })
      .then(close)
      .catch(() => setHasError(true))
  }, [selection, upsertEntry, close])

  const actions = (
    <>
      <Button variant="secondary" onClick={close} disabled={upsertEntryResult.loading}>
        Discard Bracket
      </Button>
      <Button variant="primary" onClick={handleUpsertEntry} withLoading loading={upsertEntryResult.loading}>
        {isSingleEntry ? "Replace" : "Save"}
      </Button>
    </>
  )

  const entryItems = challengeEntries.map((entry) => (
    <RadioDualLineEntryRow
      className="modal__entry-item"
      key={entry.id}
      entry={entry}
      name={`import-challenge-entry-${entry.id}`}
      showPoolInfo={false}
      isChecked={entry.id === selection}
      onChange={() => setSelection(entry.id)}
    />
  ))

  const message = isSingleEntry
    ? "Would you like to replace your existing bracket entry with this new one?"
    : "Select a bracket you would like to replace:"

  return (
    <GenericEntryModal
      isOpen={isOpen}
      title={title}
      modalType={isMobile ? "takeover" : "modal"}
      onBackgroundClick={noop}
      onEscapeKeydown={noop}
      actions={actions}
      actionProps={ACTION_PROPS}
      {...rest}
    >
      <BracketLimitModalRoot isMobile={isMobile}>
        <div className="modal__warning-row">
          <div className="modal__warning-icon">
            <WarningAlert />
          </div>
          <div className="modal__warning-text">{WARNING_TEXT}</div>
        </div>
        <div className="modal__text--body1">{message}</div>
        {!isSingleEntry && <div className="modal__entry-list">{entryItems}</div>}
        {hasError && <div className="modal__error-row modal__text--error">Could not import picks</div>}
      </BracketLimitModalRoot>
    </GenericEntryModal>
  )
}

function gameInstanceFromCookieName(cookieName?: string) {
  if (!cookieName) {
    return undefined
  }

  if (cookieName.includes(NCAAB_GAME_INSTANCE_UID)) {
    return NCAAB_GAME_INSTANCE_UID
  } else if (cookieName.includes(NCAAW_GAME_INSTANCE_UID)) {
    return NCAAW_GAME_INSTANCE_UID
  }

  return undefined
}

const ImportBracketFromCookie = ({ cookieName }: { cookieName?: string }) => {
  const history = useHistory()

  const gameInstanceUid = getStringParam(`gameInstanceUid`, useLocation().search) || gameInstanceFromCookieName(cookieName) || NCAAB_GAME_INSTANCE_UID
  const { challengeEntries, upsertEntry, upsertEntryResult, canImportPicks, discardCookie, actionType, isSingleEntry } = useCookiePicksImport(
    gameInstanceUid,
    cookieName,
  )
  const poolData = React.useContext(PoolDataContext)
  const { isInComingSoon, productSeason } = poolData ?? emptyObject
  const seasonType = productSeason?.season ?? "regular"
  const seasonYear = productSeason?.year ?? new Date().getFullYear()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const upsertEntryMutation: PicksImportType["upsertEntry"] = (options) => {
    setIsLoading(true)
    return upsertEntry(options).then((migrationResult) => {
      const updatedEntry = migrationResult.data?.upsertEntry?.entry
      // const updatedEntry = migrationResult.data?.upsertEntry?.currentUser.entries.edges.find((e) => e.node.id === updatedEntryId)
      if (updatedEntry) {
        const pickUtils = buildPickUtils(poolData as any, null, [], undefined)
        const maxPicks = pickUtils?.getMaxPicksAllowed() || 0
        const isComplete = (updatedEntry.totalPicksCount ?? 0) >= maxPicks
        const entryId = updatedEntry?.id
        history.replace({
          ...history.location,
          state: {
            key: entryId,
            saveBracketInfo: {
              isComplete,
              championId: updatedEntry.championTeam?.id,
              poolIds: [updatedEntry?.pool?.id],
              isChallengePool: true,
            },
          },
        })
      }
      return migrationResult
    })
  }

  if (canImportPicks && actionType === "ADD" && !isLoading) {
    upsertEntryMutation({
      variables: {
        gameInstanceUid,
      },
    })
  }

  const showBracketLimitModal = canImportPicks && actionType === "REPLACE" && !upsertEntryResult.called
  const showUpsertEntryNameModal = canImportPicks && actionType === "CREATE" && challengeEntries.length === 0
  return (
    <>
      <BracketLimitModal
        isOpen={showBracketLimitModal}
        close={discardCookie}
        challengeEntries={challengeEntries}
        upsertEntry={upsertEntryMutation}
        upsertEntryResult={upsertEntryResult}
        isSingleEntry={isSingleEntry}
      />
      <UpsertEntryNameModal
        title="Name Your Bracket"
        isOpen={showUpsertEntryNameModal}
        close={discardCookie}
        gameInstanceUid={gameInstanceUid}
        isCommingSoon={isInComingSoon}
        upsertEntryMutation={upsertEntryMutation}
        mode="bpc"
        productAbbrev="bpc"
        seasonType={seasonType}
        year={seasonYear}
      />
    </>
  )
}

export default ImportBracketFromCookie

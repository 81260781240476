import React, { FC, useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { emptyObject, NCAAB_GAME_INSTANCE_UID, NCAAW_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"

import styled from "styled-components"
import { Formik, Form, Field, ErrorMessage } from "formik"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import Input from "@cbs-sports/sports-shared-client/build/cjs/components/Form/Input"
import Select from "@cbs-sports/sports-shared-client/build/cjs/components/Select"
import {
  fontFamily,
  fontWeights,
  newPalette,
  pxToRem,
  buildClassNames as cx,
  layout,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { extractValidationError } from "../../../../components/Form"
import constants, { NCAAB_CHALLENGE_IMPRESSION_URL_SRC, NCAAW_CHALLENGE_IMPRESSION_URL_SRC } from "../../../../../common/constants"
import NissanTMSvg from "../../../../components/icons/NissanTM"
import Checkbox from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import { buildDisclaimer, getRulesLink } from "../../../../../common/game-text"
import { IPoolData } from "../../../../../routes.d"
import { useDeviceType } from "../../../../Base/DeviceType"
import OneByOneAd from "../OneByOneAd"
import {
  ANYTHING_BUT_NUMBERS_REGEX,
  HELPER_MESSAGE,
  NISSAN_VEHICLE_OPTIONS,
  REMOVE_INVALID_CHARS_REGEX,
  US_PHONE_NUMBER_REGEX,
} from "./Common/modalData"
import { IHaveSponsorData } from "./Common/modalTypes"
import { validateEntryName, validatePhoneNumber } from "./Common/modalValidations"
import CookieUtils from "../../../../utils/cookie-utils"
import Analytics from "../../../../utils/analytics"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import WomensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WomensBracket"
import MensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/MensBracket"

const ModalActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    width: ${pxToRem(180)};
  }
  & > div.cancel-text {
    font-size: 11px;
    margin-top: 4px;
    text-decoration: underline;
    cursor: pointer;
  }
  & > div.cancel-text.disabled {
    pointer-events: none;
  }
`

const ModalConent = styled.div`
  width: ${pxToRem(440)};
  display: flex;
  flex-direction: column;
  color: ${bracketTheme.colors.overLight.white20};

  .modal__input-helper {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white50};
  }

  .modal__text--label {
    padding-left: 1rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    color: ${newPalette.gray20};
    font-weight: ${fontWeights.bold};
    margin-bottom: 0.25rem;
  }

  .modal__error-message {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.red};
  }

  & input.has-errors {
    border-color: ${bracketTheme.colors.overLight.red};
    & + label.input-label {
      border-color: ${bracketTheme.colors.overLight.red};
    }
  }

  .modal__wrapper--select {
    max-width: ${pxToRem(159)};
  }

  .modal__wrapper--input {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .modal__wrapper-subtitle {
    margin-bottom: 1rem;
    font-size: ${pxToRem(14)};
    color: ${newPalette.gray40};
    line-height: 1rem;
    text-align: center;

    & > strong {
      color: ${newPalette.gray20};
      font-weight: ${bracketTheme.fonts.weights.semibold};
    }
  }

  & > .news-and-rules {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background: ${newPalette.gray97};
    border: solid 1px ${newPalette.gray90};
    border-radius: 0.25rem;

    .modal__text--disclaimer {
      font-size: ${pxToRem(10)};
      line-height: ${pxToRem(14)};
      color: ${newPalette.gray40};
    }

    & > .news-wrap {
      .label-content {
        font-family: ${fontFamily.base};
        font-size: ${pxToRem(14)};
        font-style: normal;
        font-weight: ${fontWeights.regular};
        line-height: 1.25rem;
        letter-spacing: -0.1px;
        text-align: left;
        margin-left: ${pxToRem(28)};
        color: ${newPalette.gray50};
      }
    }
    & > .rules-wrap {
      font-size: ${pxToRem(10)};
      line-height: ${pxToRem(14)};
      color: ${newPalette.gray40};

      a {
        text-decoration: underline;

        &:hover,
        &:active {
          color: ${newPalette.lightBlue3};
        }
      }
    }
  }
`
const BpcUpsertEntryNameModalHeaderWrap = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  & > .modal-title {
    align-items: center;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    & > h2 {
      font-size: ${pxToRem(20)};
      font-weight: ${bracketTheme.fonts.weights.bold};
      line-height: 1.5rem;
      color: ${newPalette.gray20};
      text-transform: uppercase;
    }
  }

  & > .logos-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > .logo-content {
      height: 5rem;
      width: 5rem;
    }
    & > .logo-content.nissan-logo {
      height: 4rem;
    }

    & > .divider {
      height: 4rem;
      width: 1px;
      margin: 0 1.5rem;
      background-color: ${newPalette.gray90};
    }
  }

  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    & > .modal-title > h2 {
      font-size: ${pxToRem(18)};
    }
  }
`

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  background: ${newPalette.white};
  width: ${pxToRem(472)};
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;

  .footer__disclaimer {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.1px;
    color: ${newPalette.gray20};
    flex: 1;
    padding-right: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;

    a {
      text-decoration: underline;

      &:hover,
      &:active {
        color: ${newPalette.lightBlue3};
      }
    }
  }

  ${ModalActions} {
    margin: 0;
    width: "auto";
  }
`

const BpcDisclaimerWrapper = styled.div`
  font-size: ${pxToRem(10)};
  line-height: ${pxToRem(14)};
  color: ${newPalette.gray40};
`

const BpcUpsertEntryNameModalHeader = ({ isWomenBracket }: { isWomenBracket?: boolean }) => {
  return (
    <BpcUpsertEntryNameModalHeaderWrap>
      <div className="modal-title">
        <h2>Bracket Saved!</h2>
      </div>
      <div className="logos-container">
        <div className="logo-content">{isWomenBracket ? <WomensBracketSvg /> : <MensBracketSvg />}</div>
        <div className="divider"></div>
        <div className="logo-content nissan-logo">
          <NissanTMSvg />
        </div>
      </div>
    </BpcUpsertEntryNameModalHeaderWrap>
  )
}

interface BpmToBpcNameEntryNameModalProps extends Omit<IGenericEntryModalProps, "title"> {
  close: (dontShowSavedModal?: boolean) => void
  gameInstanceUid?: string
  isCreate?: boolean
  seasonType?: string
  year?: number
  upsertEntryMutation?: IPoolData["upsertEntryMutation"]
  entryName?: string
  entryIdToCopy?: string
  isWomenBracket?: boolean
}

interface IUpsertEntryFormValues {
  gameInstanceUid?: string
  name: string
  entryIdToCopy?: string
}

const BpmToBpcNameEntryNameModal: FC<BpmToBpcNameEntryNameModalProps> = ({
  close,
  gameInstanceUid,
  seasonType,
  isCreate,
  year,
  upsertEntryMutation,
  entryIdToCopy,
  entryName,
  isWomenBracket,
  ...rest
}) => {
  const deviceType = useDeviceType()
  const modalContentWrapperProps: Record<string, unknown> = { style: { paddingBottom: "1rem" } }
  const modalFooterProps: Record<string, unknown> = { style: { position: "sticky", bottom: 0, background: "white" } }
  const poolData = useContext(PoolDataContext)
  const { upsertEntryMutation: poolDataUpsertEntryMutation } = poolData || emptyObject
  const mutation = upsertEntryMutation ?? poolDataUpsertEntryMutation

  const [generalError, setGeneralError] = useState<string | undefined>()
  const initialValues: IUpsertEntryFormValues & IHaveSponsorData = {
    name: entryName ?? "",
    gameInstanceUid,
    sponsorData: { name: "Nissan", optin: false, data: { vehicle: "" } },
  }

  const handleSubmit = (data: IUpsertEntryFormValues & IHaveSponsorData) => {
    setGeneralError(undefined)
    Analytics.trackAction("brackets interstitial", `${isWomenBracket ? "womens " : ""}bracket manager`, "play for prizes")
    const variables: IUpsertEntryFormValues & IHaveSponsorData = {
      ...data,
      entryIdToCopy,
      sponsorData: {
        ...data.sponsorData,
        data: {
          phoneNumber: data.sponsorData.data.phoneNumber || undefined,
          vehicle: data.sponsorData.data.vehicle || undefined,
        },
      },
    }
    return mutation({ variables })
      .then((res) => {
        close()
      })
      .catch((err) => {
        const apiErrors = extractValidationError(err)
        setGeneralError(apiErrors.message)
      })
  }

  // buildDisclaimer brings text + atag inside of the text, so we need to set the html
  const rulesUrlForDisclaimer = getRulesLink(gameInstanceUid || "", seasonType || "", "bpc", year || new Date().getFullYear(), "disclaimer") || "#"

  const impressionTrackingExtra = { sponsorName: "nissan logo", nissanLogoImpression: 1 }

  return (
    <Formik<IUpsertEntryFormValues & IHaveSponsorData>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isInitialValid={!validateEntryName(initialValues.name)}
    >
      {(formik) => {
        const modalActions = (
          <ModalFooter>
            <div className="footer__disclaimer">
              By clicking Play for Prizes you agree to the{" "}
              <a href={rulesUrlForDisclaimer} target="_blank" rel="noreferrer noopener nofollow">
                official rules
              </a>
              .
            </div>
            <ModalActions>
              <Button
                variant="primary"
                disabled={!formik.isValid}
                withLoading
                loading={formik.isSubmitting}
                type="button"
                onClick={formik.submitForm}
              >
                Play for Prizes
              </Button>
              <div
                onClick={() => {
                  formik.resetForm()
                  CookieUtils.setCookie(CookieUtils.BRACKET_UPSELL_COOKIE_NAME, "false")
                  /// this ${true} clear the data to show the SavedBracketModal.
                  close(true)
                }}
                className={cx({ disabled: formik.isSubmitting, "cancel-text": true })}
              >
                I don't want to play for prizes
              </div>
            </ModalActions>
          </ModalFooter>
        )

        return (
          <GenericEntryModal
            {...rest}
            footer={modalActions}
            onBackgroundClick={noop}
            onEscapeKeydown={noop}
            modalType={"modal"}
            modalContentWrapperProps={modalContentWrapperProps}
            modalFooterWrapperProps={modalFooterProps}
          >
            <AnalyticScreen
              feature="brackets"
              subfeature={`bracket-upsell`}
              title={`Create Entry`}
              isModal={true}
              gameInstanceUid={gameInstanceUid}
              deviceType={deviceType}
              extra={impressionTrackingExtra}
            />
            <OneByOneAd impressionUrl={isWomenBracket ? NCAAW_CHALLENGE_IMPRESSION_URL_SRC : NCAAB_CHALLENGE_IMPRESSION_URL_SRC} />
            <Form>
              <ModalConent>
                <BpcUpsertEntryNameModalHeader isWomenBracket={isWomenBracket} />
                <div className="modal__wrapper-subtitle">
                  Your bracket is entered in your pool with friends. Now <strong>Challenge the CBS Sports community</strong> by entering your bracket
                  for a chance to <strong>win a trip to the {constants.MARCH_MADNESS_SPORT_YEAR + 2} Final Four&reg;!</strong>
                </div>
                <Field name="name" validate={validateEntryName}>
                  {({ field, _, meta }) => {
                    return (
                      <>
                        <Input
                          label="Bracket Name"
                          maxLength={constants.ENTRY_NAME_MAX_LENGTH}
                          autoComplete="off"
                          className={cx({ "has-errors": Boolean(meta.touched && meta.error) })}
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="modal__error-message">{meta.error}</div>
                        ) : (
                          <div className="modal__input-helper">{HELPER_MESSAGE}</div>
                        )}
                      </>
                    )
                  }}
                </Field>
                <div className="news-and-rules">
                  <div className="news-wrap">
                    <Field name="sponsorData.optin">
                      {({ field }) => (
                        <Checkbox
                          label="I would like to receive future product news and announcements from NISSAN.*"
                          name={field.name}
                          onChange={field.onChange}
                          isChecked={field.value}
                          className="news-check-box"
                        />
                      )}
                    </Field>
                  </div>
                  {formik.values.sponsorData.optin && (
                    <>
                      <Field name="sponsorData.data.vehicle">
                        {({ field: { value, name }, form: { setFieldValue, setTouched } }) => {
                          const selectedValue = NISSAN_VEHICLE_OPTIONS.find((opt) => opt.value === value)
                          return (
                            <div className="modal__wrapper--input modal__wrapper--select">
                              <Select
                                options={NISSAN_VEHICLE_OPTIONS}
                                selectedValue={selectedValue}
                                onSelect={(item) => setFieldValue(name, item.value)}
                                onBlur={() => {
                                  setTouched({ ...formik.touched, [name]: true })
                                }}
                                id={name}
                              />
                            </div>
                          )
                        }}
                      </Field>
                      <Field name="sponsorData.data.phoneNumber" validate={validatePhoneNumber}>
                        {({ field: { onBlur, onChange, value, ...rest }, form: { setFieldValue, setTouched }, meta }) => {
                          return (
                            <div className="modal__wrapper--input">
                              <div className="modal__text--label">Phone Number</div>
                              <Input
                                placeholder="(XXX) XXX-XXXX"
                                {...rest}
                                value={value}
                                className={cx({ "has-errors": Boolean(meta.touched && meta.error) })}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const { value: currentValue } = e.target
                                  const match = currentValue.replace(ANYTHING_BUT_NUMBERS_REGEX, "").match(US_PHONE_NUMBER_REGEX)
                                  const newValue = match
                                    ? `(${match[1]}) ${match[2]}-${match[3]}`
                                    : currentValue.replace(REMOVE_INVALID_CHARS_REGEX, "")
                                  setFieldValue(rest.name, newValue)
                                }}
                                onBlur={() => {
                                  const currentValue: string = value ?? ""
                                  const match = currentValue.replace(ANYTHING_BUT_NUMBERS_REGEX, "").match(US_PHONE_NUMBER_REGEX)
                                  if (match) {
                                    const newValue = `(${match[1]}) ${match[2]}-${match[3]}`
                                    setFieldValue(rest.name, newValue)
                                  }
                                  setTouched({
                                    ...formik.touched,
                                    sponsorData: { ...formik.touched.sponsorData, data: { ...formik.touched.sponsorData?.data, phoneNumber: true } },
                                  })
                                }}
                              />
                              <ErrorMessage name="sponsorData.data.phoneNumber" component="div" className="modal__error-message" />
                            </div>
                          )
                        }}
                      </Field>
                      <div className="rules-wrap">
                        *By checking the box above, you expressly consent that CBSI can provide Nissan North America Inc, your information and agree
                        that Nissan North America Inc. may contact you about your inquiry via email, by text message or phone call (including
                        automatic telephone dialing system or an artificial or prerecorded voice) to the residential or cellular telephone number you
                        have provided, even if that telephone number is on a corporate, state, or national Do Not Call Registry. Data rates may apply.
                        You do not have to agree to receive such calls or messages as a condition of entering the Challenge. CBS Interactive, Inc.
                        assumes no responsibility for use of such information by Nissan North America, Inc. and advises you to review the{" "}
                        <a href="https://www.nissanusa.com/privacy.html" target="_blank">
                          Nissan Legal Privacy Policy | Nissan USA
                        </a>
                        .
                      </div>
                    </>
                  )}
                </div>
                <BpcDisclaimerWrapper
                  dangerouslySetInnerHTML={{
                    __html: buildDisclaimer(gameInstanceUid || "", seasonType || "", "bpc", year || new Date().getFullYear()),
                  }}
                />
                {generalError && <div className="modal__error-message">{generalError}</div>}
              </ModalConent>
            </Form>
          </GenericEntryModal>
        )
      }}
    </Formik>
  )
}

const ManagerToChallengeModal = () => {
  const poolData: PoolDataContextType = useContext(PoolDataContext)
  const history = useHistory()
  const {
    gameInstanceUid,
    productSeason,
    currentUser: { firstName, lastName, preferredEntryName },
  } = poolData || emptyObject
  const seasonType = productSeason?.season ?? "regular"
  const seasonYear = productSeason?.year ?? new Date().getFullYear()

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [entryId, setEntryId] = useState<string>("")

  const hard_coded_game_instance = gameInstanceUid === NCAAW_GAME_INSTANCE_UID ? NCAAW_GAME_INSTANCE_UID : NCAAB_GAME_INSTANCE_UID
  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid || "")

  const openConfirmationModal = () => setShowConfirmation(true)
  const closeConfirmationModal = (dontShowSavedModal = false) => {
    setShowConfirmation(false)
    if (dontShowSavedModal || history?.location?.state?.["upsellDataInfo"]) {
      const state: any = { ...history.location.state }
      delete state.upsellDataInfo
      if (dontShowSavedModal) {
        delete state.saveBracketInfo
        Analytics.trackAction("brackets interstitial", `${isWomenBracket ? "womens " : ""}bracket manager`, "I dont want to play for prizes")
      }
      if (state?.["saveBracketInfo"]) {
        state.saveBracketInfo.isChallengePool = true
      }
      history.replace({ ...history.location, state })
    }
  }

  useEffect(() => {
    if (history.location.state && history.location.state["upsellDataInfo"]) {
      const { managerEntryId } = history.location.state["upsellDataInfo"]
      openConfirmationModal()
      setEntryId(managerEntryId || "")
    }
  }, [history.location.state])

  const nameToUse = firstName && lastName ? `${firstName} ${lastName.charAt(0)}` : preferredEntryName

  const entryName = `${nameToUse ?? ""} Challenge${Date.now().toString().slice(-4)}`

  return (
    <BpmToBpcNameEntryNameModal
      isOpen={showConfirmation}
      close={closeConfirmationModal}
      isCreate
      entryName={entryName}
      gameInstanceUid={hard_coded_game_instance}
      seasonType={seasonType}
      year={seasonYear}
      entryIdToCopy={entryId}
      isWomenBracket={isWomenBracket}
    />
  )
}

export default ManagerToChallengeModal
